import { combineReducers } from 'redux'
//
import bulletins from './bulletins/reducer'

import customers from './customers/reducer'
import carriers from './carriers/reducer'
import truckTypes from './truckTypes/reducer'
import possibleDates from './possibleDates/reducer'
import lastVehicles from './lastVehicles/reducer'
import lastDrivers from './lastDrivers/reducer'

export default combineReducers({
  customers,
  carriers,
  bulletins,
  truckTypes,
  possibleDates,
  lastVehicles,
  lastDrivers,
})
