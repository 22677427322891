import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { PATHS } from '../consts/paths'
import { useProfile } from 'hooks/index'

import AdminRoutes from './admin.routes'
import CommonRoutes from './common.routes'
import CarrierRoutes from './carrier.routes'
import Route from './Route'

export function ContentRoutes() {
  const profile = useProfile()

  const companyType = profile.web_company?.wee_tipo
  const userType = profile.web_user?.weu_tipo

  function renderRoutes() {
    return (
      <>
        {profile.isCarrier && <CarrierRoutes />}
        {profile.isAdminUser && <AdminRoutes companyType={companyType} />}
        <CommonRoutes />
        <Route
          path={PATHS.root || '/'}
          exact
          isPrivate
          component={() => (
            <Redirect to={PATHS.defaultPath(companyType, userType)} />
          )}
        />
      </>
    )
  }

  return <Switch>{renderRoutes()}</Switch>
}

export default ContentRoutes
