import React from 'react'

import { useProfile } from '@/hooks/useProfile'
import { PATHS } from '@/consts/paths'
import { MenuItem } from '../MenuItem'
import { Domain, Group } from '../../Icons'

import { Box, Divider } from '@material-ui/core'
import { Settings } from '@material-ui/icons'

export const AdminMenuItems = () => {
  const profile = useProfile()
  return (
    <>
      {profile.isAdminCompany && (
        <MenuItem Icon={Domain} label="Empresas" path={PATHS.admin.companies} />
      )}

      <MenuItem Icon={Group} label="Usuários" path={PATHS.admin.users} />

      {profile.isAdminCompany && !profile.isDeveloper && (
        <Box display="flex">
          <Divider
            flexItem
            orientation="vertical"
            style={{ marginLeft: '0.5rem', marginRight: '2rem' }}
          />

          <MenuItem
            Icon={Settings}
            label="Preferências"
            path={PATHS.admin.settings}
          />
        </Box>
      )}
    </>
  )
}

export default AdminMenuItems
