import { alpha, Button } from '@material-ui/core'
import styled, { css } from 'styled-components'
import theme, { colors } from 'theme'

export const textEditorStyles = css`
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;

    li {
      list-style: initial;
      text-decoration: initial;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid ${alpha(colors.color100, 0.45)};
  }

  hr {
    border: none;
    border-top: 2px solid ${alpha(colors.color100, 0.45)};
    margin: 2rem 0;
  }
`

export const TextEditorContainer = styled.div`
  min-height: 400px;

  padding: 1rem;
  margin: 0.5rem 0;
  border: 1px solid rgba(96, 125, 139, 0.2);
  border-radius: 4px;

  .tiptap {
    ${textEditorStyles}
  }
`

export const ToolbarContainer = styled.header`
  padding: 1rem 0;
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(96, 125, 139, 0.2);

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    gap: 1rem;
  }
`

export const ToolButton = styled(Button)`
  height: 42px;
  border: 1px solid rgba(96, 125, 139, 0.2);

  color: ${colors.color500};
  font-size: 0.75rem;

  .header {
    font-weight: bold;
    text-transform: uppercase;
  }

  &.is-active {
    background-color: ${theme.palette.action.selected};
  }
`
