import styled from 'styled-components'
import theme from '../../theme'

import { Chip } from '@material-ui/core'

export const StyledChip = styled(Chip)`
  min-width: 80px;
  height: 26px;

  background-color: ${(props) => props.background};

  .MuiChip-icon,
  .MuiChip-label {
    color: ${() => theme.palette.common.white};
  }

  .MuiChip-label {
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 0.65rem;
    letter-spacing: 0.5px;
  }

  &.MuiChip-sizeSmall {
    height: 20px;

    .MuiChip-label {
      font-size: 0.6rem;
    }
  }
`
