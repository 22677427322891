import React, { useRef, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@material-ui/core'

import { Yup, handleValidationError, validateWithSchema } from 'services/yup'
import { unmask } from 'services/masks'
import { company } from 'consts/types'
import { isActive, isCustomer } from 'services/utils'
import { handleSaveCompany } from 'store/modules/admin/companies/reducer'
import { Form, FormTextField, FormCheckbox } from 'components/CoForm'
import { ComboboxCompanyTypes, ComboboxCarriers } from 'components/Combobox'
import { Dialog } from 'components/Dialog'
import { TabNavigation, Tabs } from 'components/TabNavigation'

import { StyledButton } from '../styles'
import { FormWrapper, Panel, Container, StyledList } from './styles'
import AccessButton from './AccessButton'
import { cnpj } from 'consts/regexp'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const DEFAULT_COMPANY_TYPE = company.TRANSPORTADORA

const initialFormData = {
  wee_tipo: DEFAULT_COMPANY_TYPE,
  wee_ativo: true,
  wee_email: '',
  cod_empresa: '',
  dsc_empresa: '',
  emp_razao_social: '',
  emp_cidade: '',
  dsc_uf: '',
}

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

const requiredOnType = (types) =>
  Yup.string().when('wee_tipo', (type, scheme) => {
    return [...types].includes(type)
      ? scheme.required('Informe o campo')
      : scheme
  })

const webCompanyValidationScheme = Yup.object().shape({
  cod_empresa: Yup.string().required('Informe o código da empresa'),
  wee_tipo: Yup.string().required('Informe o tipo da empresa'),
  wee_email: Yup.string().required('Informe o email da empresa'),
  dsc_empresa: requiredOnType([company.ADMIN, company.ECOSIS]),
  emp_razao_social: requiredOnType([company.ADMIN, company.ECOSIS]),
  emp_cidade: requiredOnType([company.ADMIN, company.ECOSIS]),
  dsc_uf: requiredOnType([company.ADMIN, company.ECOSIS]),
})

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function FormEdit({ current: webCompany, onClose, ...props }) {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.admin.companies.loading)

  const formRef = useRef(null)
  const [companyType, setCompanyType] = useState(DEFAULT_COMPANY_TYPE)
  const isEditing = Boolean(webCompany.id)

  useEffect(() => {
    if (webCompany.id) {
      const { company } = webCompany

      const formValues = {
        wee_tipo: webCompany.wee_tipo,
        wee_ativo: isActive(webCompany.wee_ativo),
        wee_email: webCompany.wee_email,
        cod_empresa: webCompany.cod_empresa,
        dsc_empresa: company.dsc_empresa,
        emp_razao_social: company.emp_razao_social,
        emp_cidade: company.emp_cidade,
        dsc_uf: company.dsc_uf,
      }

      formRef.current.setData(formValues)
      setCompanyType(formValues.wee_tipo)
    }
  }, [webCompany])

  const handleCompanyType = (_, companyType) => {
    setCompanyType(companyType?.value || DEFAULT_COMPANY_TYPE)
    formRef.current.setFieldValue('cod_empresa', '')
  }

  const handleSave = () => {
    const values = formRef.current.values

    validateWithSchema(
      webCompanyValidationScheme,
      values,
      (values) => {
        dispatch(
          handleSaveCompany({
            formValues: { ...values, cod_empresa: unmask(values.cod_empresa) },
            webCompany,
          })
        )
      },
      handleValidationError(formRef.current)
    )
  }

  const renderRegisterForm = useMemo(() => {
    if (companyType === company.TRANSPORTADORA) {
      return (
        <ComboboxCarriers
          search={webCompany?.company?.emp_razao_social}
          label="Escolha a Transportadora"
          name="cod_empresa"
          disabled={isEditing}
          inputProps={{
            size: 'medium',
            variant: 'standard',
          }}
        />
      )
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormTextField
              fullWidth
              name="cod_empresa"
              label="CNPJ"
              size="small"
              variant="filled"
              required
              textMaskProps={{ mask: cnpj.array }}
              disabled={isEditing}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              fullWidth
              name="dsc_empresa"
              label="Fantasia"
              size="small"
              variant="filled"
              required
              disabled={isEditing}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              fullWidth
              name="emp_razao_social"
              label="Razão Social"
              size="small"
              variant="filled"
              required
              disabled={isEditing}
            />
          </Grid>
          <Grid item xs={8}>
            <FormTextField
              fullWidth
              name="emp_cidade"
              label="Cidade"
              size="small"
              variant="filled"
              required
              disabled={isEditing}
            />
          </Grid>
          <Grid item xs={4}>
            <FormTextField
              fullWidth
              name="dsc_uf"
              label="UF"
              size="small"
              variant="filled"
              required
              disabled={isEditing}
            />
          </Grid>
        </Grid>
      </>
    )
  }, [webCompany, companyType, isEditing])

  const isCustomerCompany = isEditing ? isCustomer(webCompany.wee_tipo) : false

  const title = isEditing
    ? webCompany.company.emp_razao_social
    : 'Cadastrar Empresa'

  return (
    <Form
      formRef={formRef}
      initialData={initialFormData}
      schema={webCompanyValidationScheme}
    >
      <Dialog
        {...props}
        fullWidth
        title={title}
        actions={
          <>
            <StyledButton
              label="Cancelar"
              variant="outlined"
              color="primary"
              onClick={onClose}
            />
            <StyledButton
              loading={loading}
              label="Salvar"
              variant="contained"
              color="primary"
              onClick={handleSave}
            />
          </>
        }
      >
        <TabNavigation>
          <Container>
            {isCustomerCompany && (
              <Tabs
                className="unstyled"
                tabs={[
                  { value: 0, label: 'Perfil' },
                  { value: 1, label: 'Acessos' },
                ]}
              />
            )}
            <Panel index={0}>
              <FormWrapper>
                <ComboboxCompanyTypes
                  onAfterChange={handleCompanyType}
                  label="Tipo"
                  name="wee_tipo"
                  disabled={isEditing}
                  inputProps={{
                    size: 'medium',
                    variant: 'standard',
                  }}
                />
                {renderRegisterForm}
                <FormTextField
                  name="wee_email"
                  label="E-mail"
                  type="email"
                  size="medium"
                  variant="standard"
                />
                {isEditing && (
                  <div>
                    <FormCheckbox name="wee_ativo" label="Ativo" />
                  </div>
                )}
              </FormWrapper>
            </Panel>
            <Panel index={1} className="Panel-accesses">
              <StyledList>
                <AccessButton />
              </StyledList>
            </Panel>
          </Container>
        </TabNavigation>
      </Dialog>
    </Form>
  )
}

FormEdit.propTypes = {
  current: PropTypes.object,
}

export default FormEdit
