import React from 'react'
import PropTypes from 'prop-types'

import { Box, CircularProgress, Typography } from '@material-ui/core'
import { TableCell, TableRow, TableSpinnerContainer } from './styles'
import { Subject } from '@material-ui/icons'

// ---------------------------------------------------------

export function TableSpinner() {
  return (
    <TableSpinnerContainer>
      <CircularProgress size={45} color="primary" />
    </TableSpinnerContainer>
  )
}

// ---------------------------------------------------------

export function TableNoData(props) {
  const { empty, emptyText, colSpan, loading } = props

  if (!empty) return null

  return (
    <TableRow>
      <TableCell align="center" colSpan={colSpan}>
        <Box
          height="69vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {loading ? (
            <TableSpinner />
          ) : (
            <Box>
              <Subject fontSize="large" />

              <Typography variant="body1" style={{ marginTop: '1rem' }}>
                {emptyText || 'Nenhum registro encontrado'}
              </Typography>
            </Box>
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

TableNoData.propTypes = {
  emptyText: PropTypes.string,
  empty: PropTypes.bool,
  loading: PropTypes.bool,
  colSpan: PropTypes.number,
}

TableNoData.defaultProps = {
  loading: false,
}
