import { createTheme } from '@material-ui/core/styles'
import { getProfileTheme } from 'services/colors'

// ╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗╔╦╗╔═╗
// ║║║║╣  ║ ╠═╣ ║║╠═╣ ║ ╠═╣
// ╩ ╩╚═╝ ╩ ╩ ╩═╩╝╩ ╩ ╩ ╩ ╩

export const colors = {
  notification: '#FF6767',
  background: '#F7F7F7',
  backgroundTable: '#F6F6F6',
  white: '#FFF',
  ...getProfileTheme(),
}

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.color500,
    },
    secondary: {
      main: colors.main,
    },
  },
  typography: {
    fontFamily: ['Roboto Slab', 'sans-serif'].join(','),
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 12,
    },
    button: {
      textTransform: 'unset',
    },
  },
})

export default theme
