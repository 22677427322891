import { all } from 'redux-saga/effects'

import auth from './auth/sagas'
import user from './user/sagas'

import adminCompanies from './admin/companies/sagas'
import adminUsers from './admin/users/sagas'
import adminSettings from './admin/settings/availableDates/sagas'

import userDashboard from './users/dashboard/sagas'
import userOrdersForCollect from './users/ordersForCollect/sagas'
import userCollections from './users/collections/sagas'
import userSchedules from './users/schedules/sagas'
import userQueries from './users/queries/sagas'
import userReleases from './users/releases/sagas'
import userOrders from './users/orders/orders/sagas'
import userProducts from './users/orders/products/sagas'

import commons from './comuns/sagas'

function* combineSagas() {
  yield all([
    auth,
    user,
    adminCompanies,
    adminUsers,
    adminSettings,
    //
    userDashboard,
    userOrdersForCollect,
    userCollections,
    userSchedules,
    userQueries,
    userReleases,
    userOrders,
    userProducts,
    commons,
  ])
}

export default combineSagas
