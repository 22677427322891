import React, { useRef } from 'react'
import { addDays } from 'date-fns'
// import PropTypes from "prop-types";

import { placa } from 'consts/regexp'

import { Search, Delete } from '@material-ui/icons'
import { Title as RankingTitle, Divider } from 'components/Ranking/styles'
import SideBar from 'components/SideBar'
import Button from 'components/Button'

import { StyledForm } from './styles'
import { FormDatePicker, FormTextField } from 'components/CoForm'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function Title({ children }) {
  return (
    <RankingTitle>
      <span>{children}</span>
      <Divider />
    </RankingTitle>
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function Filter({ onFilter, initialValues, ...props }) {
  const formRef = useRef(null)

  function handleClearForm() {
    formRef.current.setValues(initialValues)
    onFilter(initialValues)
  }

  const minDate = addDays(new Date(), -365)

  return (
    <SideBar
      {...props}
      ContentComponent={
        <StyledForm
          formRef={formRef}
          onSubmit={(values) => onFilter({ ...values })}
          initialData={initialValues}
        >
          <Title>Buscar Notas Fiscais</Title>
          <div className="row__fields">
            <FormDatePicker
              disableToolbar
              variant="inline"
              name="nf_data_emissao.initial"
              label="Data Inicial"
              minDate={minDate}
            />
            <FormDatePicker
              disableToolbar
              variant="inline"
              name="nf_data_emissao.final"
              label="Data Final"
            />
          </div>

          {/* <Title>Pesquisar por NF</Title> */}
          <FormTextField label="NF" name="nf_numero" />

          {/* <Title>Pesquisar por Placa</Title> */}
          <FormTextField
            label="Placa"
            name="nf_placa_numero"
            textMaskProps={{ mask: placa.array }}
            uppercase
          />

          {/* <Title>Pesquisar por Motorista</Title> */}
          <FormTextField
            label="Motorista"
            name="shipping_order.ford_nome_motorista"
          />
          <div className="buttons">
            <Button type="submit" label="Buscar" startIcon={<Search />} />
            <Button
              type="button"
              label="Limpar"
              startIcon={<Delete />}
              onClick={handleClearForm}
            />
          </div>
        </StyledForm>
      }
    />
  )
}

Filter.propTypes = {}

export default Filter
