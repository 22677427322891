import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Tooltip, useMediaQuery } from '@material-ui/core'
import { Message } from '@material-ui/icons'

import * as medias from 'consts/medias'
import { usePagination } from 'hooks'
import { createQueryParameters } from 'services/utils'
import { getBulletinsRequest } from 'store/modules/comuns/bulletins/reducer'

import Paper from 'components/Paper'
import Table from 'components/Table'
import TablePagination from 'components/TablePagination'

export function BulletinsTable({ status, onClickBulletin }) {
  const sm = useMediaQuery(medias.sm)
  const dispatch = useDispatch()

  const bulletins = useSelector((state) => state.comuns.bulletins.bulletins)
  const loading = useSelector((state) => state.comuns.bulletins.loading)
  const count = useSelector((state) => state.comuns.bulletins.count)

  const { onChangePage, onChangeRowsPerPage, rowsPerPage, page } =
    usePagination()

  useEffect(() => {
    const queryParameters = createQueryParameters({
      page,
      rowsPerPage,
      status,
    })

    dispatch(getBulletinsRequest(queryParameters))
  }, [dispatch, status, page, rowsPerPage, sm])

  return (
    <Paper className="border-none">
      <Table
        size="medium"
        data={bulletins}
        loading={loading}
        columns={[
          { title: 'AVISO', field: 'id' },
          {
            title: 'EMISSÃO',
            field: 'vc_dinicial',
            cellFormat: 'date',
          },
          { title: 'REMETENTE', field: 'vc_remetente' },
          { title: 'ASSUNTO', field: 'vc_assunto' },
          {
            renderCell: ({ rowData: bulletin }) => (
              <Tooltip title="Ler aviso">
                <Message onClick={() => onClickBulletin(bulletin)} />
              </Tooltip>
            ),
            role: 'button',
          },
        ]}
      />
      <TablePagination
        count={count}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    </Paper>
  )
}
