const xs = '(max-width: 576px)'

const sm = '(max-width: 700px)'

const md = '(max-width: 900px)'

const lg = '(min-width:1280px)'

const downLg = '(max-width:1280px)'

const xl = '(max-width: 1536px)'

const print = 'print'

export { xs, sm, md, lg, downLg, xl, print }
