import styled from 'styled-components'
import { colors } from 'theme'

export const Container = styled.div``

export const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 1;
    min-width: 50%;
    margin-top: 5px;
  }

  .PrivateNotchedOutline-legendNotched-4 {
    max-width: 80px;
  }

  &:not(.inline) {
    gap: 4px;
  }

  &.inline {
    gap: none;

    > div:nth-child(odd) {
      padding-right: 5px;
    }

    > div:last-child {
      padding-right: 0;
    }
  }
`

export const ButtonArea = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  button {
    padding: 3px 16px 3px 12px;
    .MuiButton-label {
      color: ${colors.color500};
    }
  }
`
