/* eslint-disable react/no-children-prop */
import React from 'react'

import { TabNavigation, TabPanel, Tabs } from '@/components'
import { Box, Container } from '@material-ui/core'
import { SettingsPaper } from './styles'

import { AvailableDates } from './available-dates'

// -----------------------------------------------

const SETTINGS_TABS = [{ value: 0, label: 'Datas Disponíveis' }]

// -----------------------------------------------

export default function AdminSettingsPage() {
  return (
    <TabNavigation>
      <Container maxWidth="sm">
        <Box
          display="flex"
          flexDirection="column"
          gridGap="0.5rem"
          paddingTop="2rem"
        >
          <Tabs tabs={SETTINGS_TABS} />

          <SettingsPaper>
            <TabPanel index={0} children={<AvailableDates />} />
          </SettingsPaper>
        </Box>
      </Container>
    </TabNavigation>
  )
}
