import React, { useCallback, useMemo, useState } from 'react'
import { Box, Button, Tooltip, useMediaQuery } from '@material-ui/core'
import { KeyboardArrowDown as ArrowDown } from '@material-ui/icons'

import { translate } from '@/_i18n'
import * as medias from '@/consts/medias'
import { createCollectTableData } from '@/services/collects'
import formatter from '@/services/formatter'
import theme from '@/theme'

import { RenderTableRow, StyledCheckBox } from '@/components/Table'
import {
  GroupBody,
  GroupContainer,
  GroupHeader,
} from '@/components/Table/TableGroup'

import {
  isEditableOrderItem,
  isSelectableOrderItem,
  // ORDER_TYPES_LABELS,
} from '@/_mock/orders'
import ChipStatus from '@/components/ChipStatus'
import MultiSelectionTable from '@/components/MultiSelectionTable'

import { CollectForm } from './FormEdit'
import { Container } from './styles'
import { OrderTypeBadge } from '../OrderTypeBadge'

// -------------------------------------------------------------

function TableCollect({ data: collects, getRef, onUpdate, ...props }) {
  const sm = useMediaQuery(medias.sm)

  const [currentRow, setCurrentRow] = useState(null)

  const isEditing = Boolean(currentRow)

  const tableData = useMemo(() => createCollectTableData(collects), [collects])

  const summary = useMemo(() => {
    return collects.reduce(
      (acc, collect) => {
        acc.totalPallets += Number(collect.vpt_pallet)
        acc.totalAmount += Number(collect.vpt_qtd)
        acc.totalBoxes += Number(collect.vpt_qtd_caixas)
        acc.totalWeight += Number(collect.vpt_peso_bruto)
        return acc
      },
      {
        totalPallets: 0,
        totalAmount: 0,
        totalBoxes: 0,
        totalWeight: 0,
      }
    )
  }, [collects])

  const renderSchedule = useCallback(({ cellValue: schedule }) => {
    const currentDate = new Date()
    const isImmediate = new Date(schedule) <= new Date(currentDate)

    return schedule ? (
      !isImmediate ? (
        <ChipStatus
          label={isImmediate ? 'Imediata' : formatter(schedule).toSimpleDate()}
          background={theme.palette.warning.main}
        />
      ) : (
        'Imediata'
      )
    ) : null
  }, [])

  const renderUpdateAmount = useCallback((props) => {
    const { rowData: row, cellValue: value, isGroupRow } = props

    const isEditable = !isGroupRow && value > 1 && isEditableOrderItem(row.tipo)

    if (isEditable) {
      return (
        <Tooltip title="Alterar quantidade">
          <Button
            variant="outlined"
            endIcon={<ArrowDown fontSize="small" />}
            color="primary"
            size="small"
            onClick={() => setCurrentRow(row)}
          >
            {value}
          </Button>
        </Tooltip>
      )
    }

    return <span>{value}</span>
  }, [])

  return (
    <Container {...props}>
      <MultiSelectionTable
        groupBy="cod_pedido"
        data={tableData}
        getRef={getRef}
        onUpdate={onUpdate}
        isAvailable={() => true}
        columns={({ onSelectRow }) => [
          {
            title: translate('orders.table.order'),
            field: 'cod_pedido',
            renderCell: ({ rowData: order }) => {
              if (isSelectableOrderItem(order?.tipo)) {
                return (
                  <StyledCheckBox
                    value={order.selected}
                    checked={order.selected}
                    onChange={onSelectRow(order)}
                    disabled={order.vpt_bloqueia_exclusao}
                  />
                )
              }

              return null
            },
          },
          {
            title: translate('orders.table.product'),
            field: 'dsc_abreviado',
          },
          {
            title: translate('orders.table.quantity'),
            field: 'vpt_qtd',
            align: 'right',
            cellFormat: 'decimal',
          },
          {
            title: translate('orders.table.weight'),
            field: 'vpt_peso_bruto',
            align: 'right',
            cellFormat: 'decimal',
          },
          {
            title: translate('orders.table.pallets'),
            field: 'vpt_pallet',
            align: 'right',
            renderCell: renderUpdateAmount,
          },
          {
            title: translate('orders.table.boxes'),
            field: 'vpt_qtd_caixas',
            align: 'right',
            renderCell: renderUpdateAmount,
          },
          {
            title: translate('orders.table.delivery'),
            field: 'vpt_data_prev_entrega',
            renderCell: renderSchedule,
          },
          {
            title: translate('orders.table.representative'),
            field: 'rep_nome',
          },
          // {
          //   title: 'TIPO',
          //   field: 'tipo',
          //   renderCell: ({ cellValue: type }) =>
          //     ORDER_TYPES_LABELS[type] || type,
          // },
        ]}
        components={({
          onSelectTable,
          onSelectGroup,
          getTableChecked,
          getGroupChecked,
        }) => ({
          Header: () => {
            const firstCollect = collects[0]
            const checked = getTableChecked()

            return (
              <GroupContainer>
                <StyledCheckBox checked={checked} onChange={onSelectTable} />

                <div>
                  <GroupHeader>
                    <h3 className="group__title">
                      {firstCollect?.cliente_nome}
                    </h3>
                    <span className="group__info">{firstCollect?.dsc_uf}</span>
                  </GroupHeader>
                  <GroupBody>
                    <span>{firstCollect?.endereco_cliente}</span>
                    <span>{firstCollect?.cli_cidade}</span>
                    <span>
                      {formatter(firstCollect?.cod_cliente).toCNPJorCPF()}
                    </span>
                  </GroupBody>
                </div>
              </GroupContainer>
            )
          },

          Group: ({ rowData: row, ...props }) => {
            const date = formatter(row?.ped_data_emissao).toSimpleDate()
            const checked = getGroupChecked(row)

            return (
              <RenderTableRow
                {...props}
                rowData={{
                  dsc_abreviado: sm ? date : `Emitido em ${date}`,
                  cod_pedido: () => (
                    <Box display="flex" gridGap="1rem" alignItems="center">
                      <span>
                        <StyledCheckBox
                          checked={checked}
                          value={checked}
                          onChange={onSelectGroup(row)}
                          disabled={!row || row?.vpt_bloqueia_exclusao}
                        />
                        {row.cod_pedido}
                      </span>

                      <OrderTypeBadge variant={row.tipo} />
                    </Box>
                  ),
                }}
              />
            )
          },

          Footer: (props) => {
            return (
              <RenderTableRow
                {...props}
                rowData={{
                  cod_pedido: 'TOTAL',
                  vpt_pallet: summary.totalPallets,
                  vpt_qtd: summary.totalAmount,
                  vpt_qtd_caixas: summary.totalBoxes,
                  vpt_peso_bruto: summary.totalWeight,
                }}
              />
            )
          },
        })}
      />

      <CollectForm
        current={currentRow}
        open={isEditing}
        onClose={() => setCurrentRow(null)}
      />
    </Container>
  )
}

export default TableCollect
