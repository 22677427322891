import styled from 'styled-components'

import { colors } from '../../../theme'
import { Avatar, Fab } from '@material-ui/core'

export const StyledAvatar = styled(Avatar)`
  background-color: ${colors.color200};
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
`

export const ComboContainer = styled.div`
  min-width: 200px;
`

export const StyledFab = styled(Fab)`
  z-index: 1;
  position: fixed;
  bottom: 10px;
  right: 10px;

  width: 50px;
  height: 50px;

  background-color: ${colors.color500};

  .MuiSvgIcon-root {
    color: ${colors.white};
  }
`
