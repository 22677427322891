import styled from 'styled-components'

// ------------------------------------------------------------------------

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  border-bottom: 2px solid ${(props) => props.theme.palette.primary.light};
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;

  .MuiButton-root.reopen {
    background-color: ${({ theme }) => theme.palette.success.main};
  }

  .MuiButton-root.print {
    background-color: ${({ theme }) => theme.palette.info.main};
  }

  .MuiButton-root.cancel {
    background-color: ${({ theme }) => theme.palette.error.light};
  }
`
