import React, { useState } from 'react'

import { Content, Paper } from '@/components/index'
import CollectPrint from '@/components/Transportadora/CollectPrint'

import { IssuedCollectsFilter } from './Filter'
import { IssuedCollectsTable } from './Table'
import { CollectQuickFormEdit } from './QuickEdit'

export default function UserIssuedCollectionsPage() {
  const [selectedFilters, setSelectedFilters] = useState([])
  const [collectForPrint, setCollectForPrint] = useState(null)

  return (
    <Content
      title="Emitidas"
      SideComponent={
        <IssuedCollectsFilter
          onFiltering={setSelectedFilters}
          selectedFilters={selectedFilters}
        />
      }
    >
      <Paper className="border-none">
        <IssuedCollectsTable
          onPrintCollect={setCollectForPrint}
          requestFilters={selectedFilters}
        />
      </Paper>

      <CollectQuickFormEdit />

      {collectForPrint && (
        <CollectPrint
          collect={collectForPrint}
          open={Boolean(collectForPrint)}
          onClose={() => setCollectForPrint(null)}
        />
      )}
    </Content>
  )
}
