import React, { useState, createContext, useCallback } from 'react'

import * as medias from 'consts/medias'
import { colors } from 'theme'
import { useProfile } from 'hooks/index'
import { Menu as MenuIcon } from '@material-ui/icons'
import { useMediaQuery, Drawer, Divider } from '@material-ui/core'

import { SignoutButton } from '../AppBar'
import { Container, StyledButton } from './styles'
import { CarrierMenuItems } from './profiles/Carriers'
import { AdminMenuItems } from './profiles/Admin'
import { CommonMenuItems } from './profiles/Common'

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

export const DrawerContext = createContext(null)

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function MenuItems() {
  const md = useMediaQuery(medias.md)
  const profile = useProfile()
  const [showMenu, setshowMenu] = useState(false)

  const open = useCallback(() => setshowMenu(true), [])
  const close = useCallback(() => setshowMenu(false), [])

  function renderMenuItems() {
    return (
      <>
        {profile.isCarrier && <CarrierMenuItems />}
        <CommonMenuItems />
        {profile.isAdminUser && <AdminMenuItems />}
      </>
    )
  }

  return md ? (
    <DrawerContext.Provider value={{ show: showMenu, open, close }}>
      <StyledButton onClick={open}>
        <MenuIcon />
      </StyledButton>
      <Drawer
        anchor="left"
        open={showMenu}
        onClose={close}
        PaperProps={{
          style: {
            background: colors.color500,
          },
        }}
      >
        {renderMenuItems()}
        <Divider />
        <SignoutButton className="MenuItem" />
      </Drawer>
    </DrawerContext.Provider>
  ) : (
    <Container>{renderMenuItems()}</Container>
  )
}

export default MenuItems
