import React from 'react'
import { useSelector } from 'react-redux'

import ContentRoutes from '../../routes/ContentRoutes'
import { AppBar, BlockAccessBulletins } from '../../components'
import { Container, ContentContainer } from './styles'

import { NewConnectionSplashScreen } from 'components/NewConnectionSplashScreen'

function LayoutPage() {
  const newConnectionLoading = useSelector(
    (state) => state.admin.companies.newConnectionLoading
  )

  if (newConnectionLoading) {
    return <NewConnectionSplashScreen />
  }

  return (
    <Container>
      <AppBar />
      <ContentContainer>
        <ContentRoutes />
      </ContentContainer>
      <BlockAccessBulletins />
    </Container>
  )
}

export default LayoutPage
