import { isAxiosError } from 'axios'
import { toast } from 'react-toastify'

const HTTP_ERRORS = {
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
}

export default function handleErrors(error, alternativeMessage) {
  if (!isAxiosError(error)) {
    return toast.error(alternativeMessage)
  }

  const status = error?.response?.status

  switch (status) {
    case HTTP_ERRORS.UNPROCESSABLE_ENTITY:
      {
        /**
         * @type {{ errors: unknown }}
         */
        const { errors } = error.response.data

        if (typeof errors === 'string') {
          toast.error(errors)
        } else {
          toast.error(
            `
            Corrija os erros apontados pelo aplicativo:

            ${Object.entries(errors).reduce((acc, [field, messages]) => {
              acc += `${field}: ${messages.join(',')} \n`
              return acc
            }, '')}

            `.trim()
          )
        }
      }

      break

    case HTTP_ERRORS.UNAUTHORIZED:
      {
        /**
         * @type {{ errors: string }}
         */
        const { message } = error.response.data

        if (message) {
          toast.error(message)
        }
      }

      break

    default:
      toast.error(alternativeMessage)
      break
  }
}
