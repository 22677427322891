import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { Chip, Tooltip } from '@material-ui/core'
import { colors } from '@/theme'
import { ORDER_TYPES_ACRONYM, ORDER_TYPES_LABELS } from '@/_mock/orders'

// ------------------------------------------------------

const StyledChip = styled(Chip)`
  font-size: 0.65rem;
  background-color: ${colors.color100};
`

// ------------------------------------------------------

export function OrderTypeBadge(props) {
  const { variant, ...other } = props

  const label = ORDER_TYPES_LABELS[variant]
  const acronym = ORDER_TYPES_ACRONYM[variant]

  return (
    <Tooltip title={label} arrow>
      <StyledChip size="small" label={acronym} {...other} />
    </Tooltip>
  )
}

OrderTypeBadge.propTypes = {
  variant: PropTypes.string,
}
