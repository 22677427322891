import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { merge } from 'ramda'
import { colors } from 'theme'
import SearchLoader from 'react-spinners/PulseLoader'
import { Search, Clear } from '@material-ui/icons'
import { StyledTextField } from './styles'
import { IconButton } from '@material-ui/core'

export function SearchInput({ onSearch, showSearchIcon, ...props }) {
  const [searchTerm, setSearchTerm] = useState(null)
  const [searching, setSearching] = useState(false)

  useEffect(() => {
    setSearching(!!searchTerm)

    const delayDebounceFn = setTimeout(async () => {
      if (searchTerm !== null) await onSearch(searchTerm)
      setSearching(false)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const handleSearchTerm = (e) => {
    const term = e.target.value
    setSearchTerm(term)
  }

  const handleClearTerm = () => {
    setSearchTerm('')
  }

  return (
    <StyledTextField
      {...props}
      InputProps={merge(props.InputProps, {
        startAdornment: showSearchIcon ? <Search fontSize="small" /> : null,
        endAdornment: (
          <div style={{ display: 'flex', width: 50 }}>
            {searching ? (
              <SearchLoader color={colors.color200} size={8} />
            ) : (
              props.InputProps?.endAdornment ||
              (searchTerm && (
                <ClearSearch onClick={handleClearTerm} disabled={!searchTerm} />
              ))
            )}
          </div>
        ),
      })}
      value={searchTerm || ''}
      onChange={handleSearchTerm}
    />
  )
}

function ClearSearch(props) {
  return (
    <IconButton size="small" {...props}>
      <Clear />
    </IconButton>
  )
}

SearchInput.propTypes = {
  showSearchIcon: PropTypes.bool,
}

SearchInput.defaultProps = {
  showSearchIcon: true,
}

export default SearchInput
