import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { groupWith, isEmpty } from 'ramda'

import { TableContainer } from '@material-ui/core'
import { Table } from '@/components/Table/styles'

import { TableCell, TableRow, TableBody, TableHead } from '@/components/Table'

import { TablePagination } from '@/components/TablePagination'
import { usePagination } from '@/hooks/usePagination'

import { issuedsRequest } from '@/store/modules/users/collections/reducer'
import { createQueryParameters, sumOf } from '@/services/utils'
import { IssuedCollectsTableHeader } from './Header'

import formatter from '@/services/formatter'
import { TableNoData } from '@/components/Table/TableNoData'

// ------------------------------------------------------------------------

export function IssuedCollectsTable({ onPrintCollect, requestFilters }) {
  const count = useSelector((state) => state.users.collections.issueds.count)
  const data = useSelector((state) => state.users.collections.issueds.issueds)
  const loading = useSelector((state) => state.users.collections.loading)

  const dispatch = useDispatch()

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } =
    usePagination({
      initialRowsPerpage: 10,
    })

  useEffect(() => {
    const filters = requestFilters.reduce((acc, item) => {
      const itemValue = item.value?.trim()

      if (acc[item.filterBy]) {
        acc[item.filterBy].push(itemValue)
      } else {
        acc[item.filterBy] = [itemValue]
      }

      return acc
    }, {})

    const queryParameters = createQueryParameters({
      page,
      rowsPerPage,
      filters,
    })

    dispatch(issuedsRequest(queryParameters))
  }, [dispatch, page, rowsPerPage, requestFilters])

  const coletas = useMemo(() => {
    /**
     * @type {unknown[][]}
     */
    const coletas = groupWith(groupById, data)

    return coletas.map((coletaItems) => {
      const sortedColetaItems = coletaItems.sort(
        (a, b) => a.cod_cliente - b.cod_cliente
      )

      return {
        header: sortedColetaItems[0],
        items: sortedColetaItems,
        summary: {
          quantity: sumOf(sortedColetaItems, 'vpt_qtd'),
          weight: sumOf(sortedColetaItems, 'vpt_peso_bruto'),
          amountPallets: sumOf(sortedColetaItems, 'vpt_pallet'),
          amountBoxes: sumOf(sortedColetaItems, 'vpt_qtd_caixas'),
        },
      }
    })
  }, [data])

  return (
    <TableContainer>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>COLETA</TableCell>
            <TableCell>PEDIDO</TableCell>
            <TableCell>CLIENTE</TableCell>
            <TableCell>CIDADE</TableCell>
            <TableCell>UF</TableCell>
            <TableCell>PRODUTO</TableCell>
            <TableCell align="right">PESO</TableCell>
            <TableCell align="right">PAL</TableCell>
            <TableCell align="right">CXS</TableCell>
            <TableCell align="right">QUANTIDADE</TableCell>
            <TableCell>REPRESENTANTE</TableCell>
            <TableCell>OC</TableCell>
          </TableRow>
        </TableHead>

        {coletas.map((coleta) => {
          return (
            <React.Fragment key={String(coleta.header.id)}>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12}>
                    <IssuedCollectsTableHeader
                      data={coleta}
                      onPrint={onPrintCollect}
                    />
                  </TableCell>
                </TableRow>

                {coleta.items.map((item) => {
                  return (
                    <TableRow key={String(item.id)}>
                      <TableCell>{item.id_coleta}</TableCell>
                      <TableCell>{item.cod_pedido}</TableCell>
                      <TableCell>{item.cliente_nome}</TableCell>
                      <TableCell>{item.cidade_cliente}</TableCell>
                      <TableCell>{item.dsc_uf}</TableCell>
                      <TableCell>{item.dsc_abreviado}</TableCell>
                      <TableCell align="right">
                        {formatter(item.vpt_peso_bruto).toDecimal()}
                      </TableCell>
                      <TableCell align="right">{item.vpt_pallet}</TableCell>
                      <TableCell align="right">{item.vpt_qtd_caixas}</TableCell>
                      <TableCell align="right">
                        {formatter(item.vpt_qtd).toDecimal()}
                      </TableCell>
                      <TableCell>{item.rep_nome}</TableCell>
                      <TableCell>{item.ford_numero}</TableCell>
                    </TableRow>
                  )
                })}

                <TableRow
                  style={{
                    backgroundColor: 'rgba(207, 216, 220, 0.15)',
                  }}
                >
                  <TableCell colSpan={6} />

                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    {formatter(coleta.summary.weight).toDecimal()}
                  </TableCell>

                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    {coleta.summary.amountPallets}
                  </TableCell>

                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    {coleta.summary.amountBoxes}
                  </TableCell>

                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    {formatter(coleta.summary.quantity).toDecimal()}
                  </TableCell>

                  <TableCell colSpan={2} />
                </TableRow>
              </TableBody>
            </React.Fragment>
          )
        })}

        <TableBody>
          <TableNoData
            empty={isEmpty(coletas)}
            loading={loading}
            emptyText="Nenhuma coleta encontrada"
            colSpan={10}
          />
        </TableBody>
      </Table>

      <TablePagination
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </TableContainer>
  )
}

IssuedCollectsTable.propTypes = {
  onPrintCollect: PropTypes.func,
}

// ------------------------------------------------------------------------

function groupById(a, b) {
  return a.id_coleta_for_order === b.id_coleta_for_order
}
