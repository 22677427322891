import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { format } from 'date-fns'

import { Add, Edit } from '@material-ui/icons'
import { Box, Button, Divider, IconButton, Typography } from '@material-ui/core'
import { Table as CustomTable } from '@/components'
import { useSortData } from '@/hooks'

import { ChipStatus } from '@/components/ChipStatus'
import { AvailableDateDialog } from './AvailableDateDialog'
import { AVAILABLE_DATE_COLORS, AVAILABLE_DATE_TYPES } from './utils'
import { availableDatesActions } from '@/store/modules/admin/settings/availableDates/reducer'

// -----------------------------------------------

export function AvailableDates() {
  const dispatch = useDispatch()

  const dates = useSelector((state) => state.admin.settings.availableDates.data)

  const loading = useSelector(
    (state) => state.admin.settings.availableDates.loading
  )

  const [currentDate, setCurrentDate] = useState(null)

  const { sortData } = useSortData({
    initialField: 'vdt_data',
    initialOrder: 'desc',
  })

  useEffect(() => {
    dispatch(availableDatesActions.getAvailableDatesRequest())
  }, [dispatch])

  const handleNew = () => {
    setCurrentDate({})
  }

  const handleEdit = useCallback(async (date) => {
    setCurrentDate(date)
  }, [])

  const handleClose = useCallback(() => {
    setCurrentDate(null)
  }, [])

  //

  const handleRemove = () => {
    const availableDateId = currentDate.vdt_data

    dispatch(
      availableDatesActions.removeAvailableDateRequest({ availableDateId })
    )

    handleClose()
  }

  const handleSave = (data, isEditing) => {
    if (isEditing) {
      dispatch(
        availableDatesActions.updateAvailableDateRequest({
          availableDateId: data.vdt_data,
          data,
        })
      )
    } else {
      dispatch(availableDatesActions.createAvailableDateRequest({ data }))
    }

    handleClose()
  }

  const possibleDates = dates.map((date) =>
    format(new Date(date.vdt_data), 'dd/MM/yyyy')
  )

  const sortedData = sortData(dates)

  return (
    <Box display="flex" flexDirection="column" gridGap="1rem">
      <Box alignSelf="flex-end">
        <Button
          onClick={handleNew}
          color="primary"
          variant="contained"
          startIcon={<Add />}
        >
          <Typography variant="body2">NOVA DATA</Typography>
        </Button>
      </Box>

      <Divider />

      <AvailableDatesTable
        onEdit={handleEdit}
        data={sortedData}
        loading={loading}
      />

      <AvailableDateDialog
        open={Boolean(currentDate)}
        currentDate={currentDate}
        possibleDates={possibleDates}
        onClose={handleClose}
        onRemove={handleRemove}
        onSave={handleSave}
      />
    </Box>
  )
}

// -----------------------------------------------

function AvailableDatesTable(props) {
  const { onEdit, ...other } = props

  const displayColumns = useMemo(() => {
    function renderActions({ rowData: date }) {
      const type = date.vdt_tipo

      return (
        <Box>
          <ChipStatus
            size="small"
            label={AVAILABLE_DATE_TYPES[type]}
            background={AVAILABLE_DATE_COLORS[type]}
          />

          <IconButton
            onClick={() => onEdit(date)}
            size="small"
            style={{ marginLeft: '1rem' }}
          >
            <Edit color="primary" fontSize="small" />
          </IconButton>
        </Box>
      )
    }

    return [
      { title: 'DATA', field: 'vdt_data', cellFormat: 'date' },
      {
        title: '',
        field: 'vdt_tipo',
        align: 'right',
        renderCell: renderActions,
      },
    ]
  }, [onEdit])

  return <CustomTable size="medium" columns={displayColumns} {...other} />
}
