import React from 'react'
import { company } from 'consts/types'
import { useProfile } from 'hooks/useProfile'
import { FormCombobox } from 'components/CoForm'
import TextField from 'components/TextField'
import ComboboxBase from '../Base'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function ComboboxCompanyTypes(props) {
  const profile = useProfile()

  const adminOptions = [
    { label: 'TRANSPORTADORA', value: company.TRANSPORTADORA },
  ]

  const developerOptions = [
    { label: 'ADMIN', value: company.ADMIN },
    { label: 'DEVELOPER', value: company.ECOSIS },
  ]

  const options = profile.isDeveloper
    ? developerOptions.concat(adminOptions)
    : adminOptions

  // Belongs to form
  if (props.name) {
    return <FormCombobox options={options} {...props} />
  } else {
    // Include all option
    options.unshift({ label: 'TODOS', value: '' })

    return (
      <ComboboxBase
        name="company_type"
        options={options}
        renderInput={(provided) => (
          <TextField label="Tipo" variant="outlined" {...provided} />
        )}
        disableClearable
        {...props}
      />
    )
  }
}

export default ComboboxCompanyTypes
