import styled from 'styled-components'
import * as medias from 'consts/medias'
import { TabPanel } from 'components/TabNavigation'
import { List } from '@material-ui/core'
import { colors } from 'theme'

export const Container = styled.div``

export const Panel = styled(TabPanel)`
  height: auto;
  overflow: hidden;
  min-height: 252px;

  &.Panel-accesses {
    padding: 14px;
  }
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  min-width: 450px;
  > div {
    margin-top: 20px;
  }

  @media ${medias.sm} {
    min-width: 250px;
    > div {
      margin-top: 10px;
    }
  }
`

export const StyledList = styled(List)`
  .MuiListItem-container {
    border-bottom: 1px solid ${colors.main};
    &:first-child {
      border-top: 1px solid ${colors.main};
    }
  }
`
