import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Box, DialogContent, Divider, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import { updateCollectItemRequest } from '@/store/modules/users/collections/reducer'

import Button from '@/components/Button'
import Dialog from '@/components/Dialog'
import { FormTextField } from '@/components/CoForm'
import { StyledForm } from './styles'

// ---------------------------------------------------------------------

export function CollectForm(props) {
  const { current, onClose, ...other } = props

  const formRef = useRef(null)

  const defaultValues = useMemo(
    () => ({
      vpt_pallet: current?.vpt_pallet || 0,
      vpt_qtd_caixas: current?.vpt_qtd_caixas || 0,
    }),
    [current]
  )

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setData(defaultValues)
    }
  }, [defaultValues])

  const dispatch = useDispatch()

  function handleSubmit(updateParams) {
    dispatch(updateCollectItemRequest({ current, updateParams }))

    onClose()
  }

  function handleSubmitForm() {
    const data = formRef.current.getValues()
    handleSubmit(data)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      title="Alterar quantidade"
      fullScreen={false}
      onClose={onClose}
      actions={
        <>
          <Button
            label="Cancelar"
            variant="outlined"
            color="primary"
            onClick={onClose}
          />

          <Button
            variant="contained"
            label="Salvar"
            color="primary"
            type="button"
            onClick={handleSubmitForm}
          />
        </>
      }
      {...other}
    >
      <DialogContent>
        <Box mb={3}>
          <Typography variant="body2" color="textSecondary">
            {current?.cliente_nome}
          </Typography>

          <Typography variant="body1" color="primary">
            {current?.cod_pedido} / {current?.dsc_abreviado}
          </Typography>
        </Box>

        <StyledForm
          formRef={formRef}
          initialData={defaultValues}
          onSubmit={handleSubmit}
        >
          <FormTextField
            fullWidth
            size="small"
            label="Paletes"
            name="vpt_pallet"
            required
            type="number"
            inputProps={{
              min: 1,
              max: defaultValues.vpt_pallet,
            }}
            disabled={defaultValues.vpt_pallet <= 1}
          />

          <Divider orientation="vertical" flexItem />

          <FormTextField
            fullWidth
            size="small"
            label="Caixas"
            name="vpt_qtd_caixas"
            required
            type="number"
            inputProps={{
              min: 1,
              max: defaultValues.vpt_qtd_caixas,
            }}
            disabled={defaultValues.vpt_qtd_caixas <= 1}
          />
        </StyledForm>
      </DialogContent>
    </Dialog>
  )
}

CollectForm.propTypes = {
  current: PropTypes.object,
  onClose: PropTypes.func,
}
