import styled from 'styled-components'
import { Slide, IconButton } from '@material-ui/core'
import * as medias from 'consts/medias'
import { colors } from 'theme'

const sizePrimaryContent = 'calc(100vh - 137px)'

export const Container = styled.div`
  height: ${sizePrimaryContent};

  overflow: auto;
  background-color: ${colors.white};

  section {
    position: relative;
    display: flex;
  }

  &.withTitle {
    padding: 11px 12px;
    background-color: ${colors.background};

    .Content-Children {
      padding: 0;
    }
  }

  &.withHeader {
    .Content-Children {
      padding-top: 0;
    }
  }

  &.loading {
    overflow: hidden;
    .Content-Children {
      opacity: 0.6;
    }
  }

  &.loading.withHeader .Content-Children {
    min-height: 75vh;
  }

  @media ${medias.sm} {
    height: auto;
    &,
    &.withTitle {
      padding: 12px 6px 6px;
    }

    section {
      flex-direction: column;
    }
  }

  @media print {
    &.printable {
      height: auto;
    }

    section {
      flex-direction: row;
    }
  }
`

export const ContentTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  margin-top: 8px;
  margin-bottom: 8px;
  padding-bottom: 8px;

  border-bottom: 2px solid ${colors.color100};

  .ContentTitle-label {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    span {
      color: ${colors.color700};
      font-size: 16px;
      line-height: 24px;
    }

    .MuiSvgIcon-root {
      margin-right: 5px;
      cursor: pointer;
      fill: ${colors.color500};

      width: 0.95rem;
      height: 0.95rem;
    }
  }

  .ContentTitle-controls {
    margin-right: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    > * {
      flex: 1;
    }

    > * + * {
      margin-left: 5px;
    }

    .MuiAutocomplete-root {
      min-width: 160px;
    }
  }

  @media ${medias.sm} {
    flex-direction: column;
    align-items: center;

    .ContentTitle-label {
      margin-bottom: 10px;
    }

    .ContentTitle-controls {
      width: 100%;
      > * {
        margin: 2.5px;
      }
    }
  }

  @media print {
    display: none;
  }
`

export const HeaderComponent = styled.div`
  background-color: ${colors.background};
  padding: 10px 12px;

  z-index: 20;
  position: sticky;
  top: -1px;

  @media ${medias.sm} {
    position: fixed;
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${colors.white};
    padding: 0;

    > div {
      border: none;
      padding: 5px 12px;
      background-color: ${colors.white};
      border-top: 2px solid ${colors.main};
    }
  }

  @media print {
    display: none;
  }
`

export const CenterComponent = styled.div`
  position: relative;
  background-color: ${colors.background};
  flex: 2;

  > .CenterComponent-SideBarControl {
    display: flex;
    align-items: center;
    padding: 10px 5px;

    background-color: ${colors.color500};
    border-radius: 0 5px 5px 0;

    z-index: 10;
    transition: 100ms ease-in;
    animation: show-sidebar-button 500ms forwards;

    position: fixed;
    top: 50%;

    cursor: pointer;
    &:hover {
      padding-left: 10px;
    }

    &.shift {
      display: none;
    }

    .MuiSvgIcon-root {
      color: ${colors.white};
    }
  }

  > .Content-Children {
    padding: 10px 12px;
  }

  @keyframes show-sidebar-button {
    from {
      left: -100px;
    }
    to {
      left: 0;
    }
  }

  @media ${medias.sm} {
    margin-bottom: 73px; // fixes the footer
    background-color: ${colors.white};
    > .Content-Children {
      padding: 0;
    }

    > .CenterComponent-SideBarControl {
      animation: none;
      margin-bottom: 5px;
      position: relative;
      > svg {
        margin: 0 auto;
      }
    }
  }

  @media print {
    > .CenterComponent-SideBarControl {
      display: none;
    }
  }
`

export const SideComponent = styled(Slide)`
  height: ${sizePrimaryContent};
  flex: 1;

  margin-right: 16px;
  background-color: ${colors.background};

  position: sticky;
  top: 0;

  max-width: 340px;
  min-width: 300px;

  overflow-y: auto;
  overflow-x: hidden;

  > .SideComponent-CloseControl {
    cursor: pointer;
    position: absolute;

    top: 1px;
    right: 1px;

    .MuiSvgIcon-root {
      color: ${colors.color500};
    }
  }

  @media ${medias.sm} {
    background-color: ${colors.white};
    margin-right: 0;
    margin-bottom: 16px;
    max-width: none;
  }
`

export const ShowSidebarMobile = styled(IconButton)`
  position: absolute;
  top: 77px;
  right: 10px;

  z-index: 3;
`
