import React from 'react'

import { all, call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { confirmationDialog } from 'components/ConfirmationDialog'
import { OrderDeliveyDateConfirmationDialog } from 'components/Transportadora/OrderDeliveyDateConfirmationDialog'
import api from 'services/api'
import handleErrors from 'services/handleErrors'
import history from 'services/history'
import { dateNotInDates, notEmpty } from 'services/utils'

import { apiEndPoints } from 'consts/apiEndPoints'
import { PATHS } from 'consts/paths'
import { store } from '../../..'
import { colors } from '@/theme'

import {
  getCollectRequest,
  getCollectSuccess,
  getCollectFailure,
  createCollectRequest,
  createCollectSuccess,
  createCollectFailure,
  finishCollectRequest,
  finishCollectSuccess,
  finishCollectFailure,
  finishCollectCancel,
  updateCollectFailure,
  updateCollectRequest,
  updateCollectSuccess,
  removeCollectItemsRequest,
  issuedsRequest,
  issuedsSuccess,
  issuedsFailure,
  updateCollectItemRequest,
  updateCollectItemFailure,
  cancelCollectFailure,
  cancelCollectRequest,
  reOpenCollectRequest,
  reOpenCollectFailure,
  reOpenCollectSuccess,
  collectCountRequest,
  collectCountSuccess,
} from './reducer'

import { getOrdersRequest } from '../ordersForCollect/reducer'

function* getCollect() {
  try {
    const response = yield call(api.get, apiEndPoints.user.collects.root)
    const collectItems = response.data

    yield put(getCollectSuccess(collectItems))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar a coleta')
    yield put(getCollectFailure())
  }
}

function* createCollect({ payload }) {
  try {
    yield call(api.post, apiEndPoints.user.collects.root, {
      collects: payload,
    })

    const response = yield call(api.get, apiEndPoints.user.collects.root)
    const collect = response.data

    yield put(getCollectSuccess(collect))
    yield put(getOrdersRequest())

    yield put(createCollectSuccess())
  } catch (error) {
    handleErrors(error, 'Não foi possível adicionar os pedidos a coleta')
    yield put(createCollectFailure())
  }
}

function* finishCollect({ payload }) {
  let dialog = {
    title: 'Finalizar Coleta',
    content: 'Confirma a finalização e envio dessa Coleta ?',
  }

  try {
    // -----------------------------------------------------------------------

    const responseDateCheck = yield call(
      api.post,
      apiEndPoints.user.collects.dateCheck,
      { vpt_dcoleta: payload.vpt_dcoleta }
    )

    const ordersWithError = responseDateCheck.data

    if (notEmpty(ordersWithError)) {
      dialog.content = (
        <OrderDeliveyDateConfirmationDialog orders={ordersWithError} />
      )
    }

    // -----------------------------------------------------------------------

    const possibleDates = store.getState().comuns.possibleDates.dates

    const isInvalidDate = dateNotInDates(payload.vpt_dcoleta, possibleDates)

    const confirm = yield call(
      confirmationDialog.open,
      dialog.title,
      dialog.content
    )

    confirmationDialog.close()

    if (!confirm || isInvalidDate) {
      if (isInvalidDate) toast.error('Data de previsão indisponível')
      yield put(finishCollectCancel())
      return
    }

    // -----------------------------------------------------------------------

    const orderCollect = {
      vpt_obs: payload.vpt_obs,
      vpt_dcoleta: payload.vpt_dcoleta,
      id_tipo_caminhao: payload.id_tipo_caminhao,
      vpt_placa: payload.vpt_placa,
      vpt_motorista: payload.vpt_motorista,
      vpt_cod_motorista: payload.vpt_cod_motorista,
      id_coleta_sugerida: null,
    }

    const suggestedCollectResponse = yield call(
      api.post,
      apiEndPoints.user.orderCollects.userSuggestedCollect,
      { order_collect: orderCollect }
    )

    const suggestedCollectId = suggestedCollectResponse.data.id_coleta

    if (suggestedCollectId) {
      const suggestedCollectConfirm = yield call(
        confirmationDialog.open,
        'Atenção',
        <>
          <div>
            Existe uma coleta em aberto para o mesmo dia para esse caminhão,
            deseja adicionar os itens a coleta {suggestedCollectId} ?
          </div>
          <br />
          <small style={{ color: colors.notification }}>
            * Caso a coleta esteja confirmada ela retornará ao status de
            aguardando confirmação.
          </small>
        </>
      )

      if (suggestedCollectConfirm) {
        Object.assign(orderCollect, { id_coleta_sugerida: suggestedCollectId })
      }

      confirmationDialog.close()
    }

    // yield put(finishCollectCancel())

    // -----------------------------------------------------------------------

    const response = yield call(
      api.post,
      apiEndPoints.user.orderCollects.root,
      { order_collect: orderCollect }
    )

    const collect = response.data

    const collectId = collect[0].id_coleta

    toast.success(`Coleta ${collectId} enviada com sucesso`)

    yield put(finishCollectSuccess(collect))
  } catch (error) {
    handleErrors(error, 'Não foi possível finalizar a coleta')
    yield put(finishCollectFailure(error.response.data.not_available))
  }
}

function* updateCollect({ payload }) {
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Atualizar Coleta',
      'Confirma a atualização dos dados da Coleta ?'
    )

    confirmationDialog.close()
    if (!confirm) {
      yield put(updateCollectFailure())
      return
    }

    yield call(api.post, apiEndPoints.user.orderCollects.updateParams, payload)
    toast.success('Coleta atualizada com sucesso')

    yield put(updateCollectSuccess())
    yield put(issuedsRequest())
  } catch (error) {
    handleErrors(error, 'Não foi possível atualizar a coleta')
    yield put(updateCollectFailure())
  }
}

function* removeCollectItems({ payload }) {
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Remover',
      'Deseja remover os items selecionados ?'
    )

    confirmationDialog.close()

    if (!confirm) return

    const collects = payload.map((collect) => ({
      weu_id: collect.weu_id,
      cod_pedido: collect.cod_pedido,
      id_cia: collect.id_cia,
      id_itens: collect.id_itens,
    }))

    yield call(api.post, apiEndPoints.user.collects.destroySelected, {
      collects,
    })

    const response = yield call(api.get, apiEndPoints.user.collects.root)
    const collect = response.data

    yield put(getCollectSuccess(collect))
  } catch (error) {
    handleErrors(error, 'Não foi possível remover os items')
  }
}

function* updateCollectItem({ payload }) {
  try {
    const { current, updateParams } = payload

    // Atualiza a quantidade de pallets
    yield call(api.patch, apiEndPoints.user.collects.onMember(current.id), {
      collect: {
        vpt_pallet: updateParams.vpt_pallet,
        vpt_qtd_caixas: updateParams.vpt_qtd_caixas,
      },
    })

    // Atualiza as coletas
    const response = yield call(api.get, apiEndPoints.user.collects.root)
    const collect = response.data

    yield put(getCollectSuccess(collect))
  } catch (error) {
    handleErrors(error, 'Não foi possível atualizar o item')
    yield put(updateCollectItemFailure())
  }
}

function* issueds({ payload }) {
  try {
    const requestBody = { params: payload }

    const response = yield call(
      api.get,
      apiEndPoints.user.orderCollects.root,
      requestBody
    )
    const issueds = response.data
    const count = parseInt(response.headers.total)
    yield put(issuedsSuccess({ issueds, count }))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar as coletas emitidas')
    yield put(issuedsFailure())
  }
}

function* cancelCollect({ payload: collect }) {
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Cancelar',
      `Confirma o cancelamento da coleta ${collect.id_coleta} ?`
    )

    confirmationDialog.close()
    if (!confirm) {
      yield put(cancelCollectFailure())
      return
    }

    yield call(api.post, apiEndPoints.user.orderCollects.cancel, {
      id_coleta: collect.id_coleta_agrupa || collect.id_coleta, // Cancelar o grupo
    })

    toast.success(`Coleta ${collect.id_coleta} cancelada`)

    // refresh issued collects
    yield put(issuedsRequest())
  } catch (error) {
    handleErrors(error, 'Não foi possível cancelar a coleta')
    yield put(cancelCollectFailure())
  }
}

function* reOpenCollect({ payload: collect }) {
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Reabrir',
      `Confirma a reabertura da coleta ${collect.id_coleta} ?`
    )

    confirmationDialog.close()
    if (!confirm) {
      yield put(reOpenCollectFailure())
      return
    }

    const response = yield call(
      api.post,
      apiEndPoints.user.orderCollects.reopen,
      {
        id_coleta: collect.id_coleta_agrupa || collect.id_coleta, // Reabrir o grupo
      }
    )

    const collectParams = response.data

    yield put(reOpenCollectSuccess(collectParams))

    // refresh issued collects
    yield put(issuedsRequest())

    history.push(PATHS.user.collect)
  } catch (error) {
    handleErrors(error, 'Não foi possível reabrir a coleta')
    yield put(reOpenCollectFailure())
  }
}

function* collectCount() {
  try {
    const response = yield call(api.post, apiEndPoints.user.collects.count)
    yield put(collectCountSuccess(response.data))
  } catch (error) {
    handleErrors(error)
  }
}

export default all([
  takeLatest(createCollectRequest.type, createCollect),
  takeLatest(finishCollectRequest.type, finishCollect),
  takeLatest(updateCollectRequest.type, updateCollect),
  takeLatest(getCollectRequest.type, getCollect),
  takeLatest(removeCollectItemsRequest.type, removeCollectItems),
  takeLatest(issuedsRequest.type, issueds),
  takeLatest(updateCollectItemRequest.type, updateCollectItem),
  takeLatest(cancelCollectRequest.type, cancelCollect),
  takeLatest(reOpenCollectRequest.type, reOpenCollect),
  takeLatest(collectCountRequest.type, collectCount),
])
