import React, { useMemo } from 'react'
import logoColorido from 'assets/images/logo-empresa.svg'
import { colors } from 'theme'
import * as medias from 'consts/medias'
import { useProfile } from 'hooks'
import { padZero } from 'services/utils'
import formatter from 'services/formatter'
import BarCode from 'react-barcode'
import { Box, Typography, useMediaQuery } from '@material-ui/core'
import {
  Print,
  LocalShipping,
  AccountCircle,
  Description,
} from '@material-ui/icons'
import { Dialog, Table } from 'components/index'
import { TableRow, TableCell } from 'components/Table/styles'
import RenderTableRow from 'components/Table/TableRow'
import {
  Container,
  Header,
  Cards,
  GroupContainer,
  StyledButton,
  StyledCard,
  StyledCardIcon,
  StyleCardBody,
} from './styles'
import { unmask } from 'services/masks'

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function Card({ Icon, title, content, ...props }) {
  return (
    <StyledCard {...props}>
      <StyledCardIcon>
        <Icon fontSize="large" />
      </StyledCardIcon>
      <StyleCardBody>
        <Typography component="div" variant="body1">
          {title}
        </Typography>
        <Typography component="div" variant="body2">
          {content}
        </Typography>
      </StyleCardBody>
    </StyledCard>
  )
}

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const columns = [
  { title: 'PEDIDO', field: 'cod_pedido' },
  { title: 'PRODUTO', field: 'dsc_produto' },
  {
    title: 'QUANTIDADE',
    field: 'vpt_qtd',
    cellFormat: 'decimal',
    align: 'right',
  },
  {
    title: 'PESO',
    field: 'vpt_peso_bruto',
    cellFormat: 'decimal',
    align: 'right',
  },
  {
    title: 'PAL',
    field: 'vpt_pallet',
    align: 'right',
  },
  {
    title: 'CXS',
    field: 'vpt_qtd_caixas',
    align: 'right',
  },
  {
    title: 'ENTREGA',
    field: 'vpt_data_prev_entrega',
    cellFormat: 'date',
  },
  { title: 'FRETE', field: 'ped_tipo_frete' },
  { title: 'REPRESENTANTE', field: 'ped_representante' },
]

export function CollectPrint({ collect, ...props }) {
  const sm = useMediaQuery(medias.sm)
  const carrier = useProfile().company
  const encryptedId = padZero(10, collect.id)

  const summary = useMemo(() => {
    return collect.items.reduce(
      (acc, item) => {
        acc.weight += Number(item.vpt_peso_bruto)
        acc.amount += Number(item.vpt_qtd)
        acc.amountPallets += Number(item.vpt_pallet)
        acc.amountBoxes += Number(item.vpt_qtd_caixas)

        return acc
      },
      {
        weight: 0,
        amount: 0,
        amountPallets: 0,
        amountBoxes: 0,
      }
    )
  }, [collect])

  const schedulePasswords = collect.schedules
    .filter((s) => s.ford_senha)
    .map((s) => `${s.ford_senha} (${formatter(s.ford_data).toSimpleDate()})`)
    .join(', ')

  function handleInviteAndPrint() {
    window.print()
  }

  const cellStyle = ({ columnName }) =>
    columnName === 'dsc_produto' ? { whiteSpace: 'nowrap' } : {}

  return (
    <Dialog
      {...props}
      fullWidth
      fullScreen={sm}
      maxWidth="lg"
      title={`Impressão Coleta ${collect.id}`}
      className="printable"
      actions={
        <>
          <StyledButton
            color="primary"
            label="Fechar"
            variant="contained"
            onClick={props.onClose}
          />
          <StyledButton
            color="primary"
            startIcon={<Print />}
            label="Imprimir"
            variant="outlined"
            onClick={handleInviteAndPrint}
          />
        </>
      }
    >
      <Container>
        {/* === BARCODE HEADER ===  */}
        <Header>
          <div className="Header-barcode">
            <BarCode
              height={sm ? 30 : 100}
              value={encryptedId}
              displayValue={false}
            />
          </div>
          <div className="Header-content">
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              Villagres - Pedido de Coleta
            </Typography>

            <Typography gutterBottom component="div" variant="body1">
              {collect.status.descr}
            </Typography>

            <Typography gutterBottom component="div" variant="h5">
              <span>{collect.id}</span>
            </Typography>

            <Typography component="div" variant="body1">
              <b>Transportadora:</b>
              <span>{carrier.emp_razao_social}</span>
            </Typography>

            <Box display="flex">
              <Typography component="div" variant="body1">
                <b>Emissão:</b>
                <span>{collect.date}</span>
              </Typography>
              {collect.lastUpdateDate && (
                <Typography
                  component="div"
                  variant="body1"
                  style={{ marginLeft: '12px' }}
                >
                  <b>Ultima Alteração:</b>
                  <span>{collect.lastUpdateDate}</span>
                </Typography>
              )}
            </Box>

            <Typography component="div" variant="body1">
              <b>Impressão:</b>
              <span>{collect.printDate}</span>
            </Typography>
          </div>
          <div className="Header-logo">
            <img src={logoColorido} alt="logo" />
          </div>
        </Header>

        {/* === CARDS ===  */}
        <Cards>
          <Card
            Icon={AccountCircle}
            title={collect.driver.name}
            content={
              <p>{formatter(unmask(collect.driver.id)).toCNPJorCPF()}</p>
            }
          />
          <Card
            Icon={LocalShipping}
            title={collect.truck.plate}
            content={<p>{collect.truck.dsc_caminhao}</p>}
          />
          <Card
            Icon={Description}
            title={`Data da Coleta: ${collect.prevDate}`}
            content={<p>{collect.obs}</p>}
          />
        </Cards>

        {/* === TABLE ===  */}
        <Table
          groupBy="cod_cliente"
          size="medium"
          data={collect.items}
          columns={columns}
          stripped={false}
          cellStyle={cellStyle}
          components={{
            Group: ({ rowData }) => {
              const customer = rowData.customer
              const customerStr = `${customer.emp_razao_social} (${formatter(
                rowData.cod_cliente
              ).toCNPJorCPF()})`
              return (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    style={{
                      borderBottom: `1px solid ${colors.color300}`,
                    }}
                  >
                    <GroupContainer>
                      <b>{customerStr}</b>
                      <span>{customer.emp_endereco}</span>
                    </GroupContainer>
                  </TableCell>
                </TableRow>
              )
            },
            Footer: (props) => {
              return (
                <RenderTableRow
                  {...props}
                  rowData={{
                    cod_pedido: 'TOTAL',
                    vpt_qtd: summary.amount,
                    vpt_peso_bruto: summary.weight,
                    vpt_pallet: summary.amountPallets,
                    vpt_qtd_caixas: summary.amountBoxes,
                  }}
                />
              )
            },
          }}
        />

        {/* === SCHEDULES === */}
        {schedulePasswords.length > 0 && (
          <Box marginY={2}>
            <Typography color="primary" component="span" variant="subtitle2">
              <Box component="b" marginRight={1}>
                Agendamentos incluidos:
              </Box>
              <span>{schedulePasswords}</span>
            </Typography>
          </Box>
        )}
      </Container>
    </Dialog>
  )
}

export default CollectPrint
