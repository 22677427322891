import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import * as medias from 'consts/medias'
import { translate } from '_i18n'
import formatter from 'services/formatter'
import { today } from 'services/utils'
import {
  getCollectRequest,
  collectWeightSelector,
  collectSelectedWeightSelector,
} from '../../store/modules/users/collections/reducer'
import { useMediaQuery } from '@material-ui/core'
import { Print } from '@material-ui/icons'
import {
  Divider,
  Container,
  ButtonsContainer,
  ContainerWeights,
  WeightText,
  StyledButton,
} from './styles'

export function CollectStatus({ onClick, onPrint, ...props }) {
  const sm = useMediaQuery(medias.sm)

  const dispatch = useDispatch()
  const weight = useSelector(collectWeightSelector)
  const selectedWeight = useSelector(collectSelectedWeightSelector)
  const loading = useSelector((state) => state.users.collections.loading)

  useEffect(() => {
    dispatch(getCollectRequest())
  }, [dispatch])

  const withSelectedWeight = selectedWeight > 0

  function translateWeightDescr(translateId, weight) {
    return translate('collect.weightText', {
      typeWeight: translate(translateId),
      weight: <strong>{formatter(weight).toDecimal()}</strong>,
    })
  }

  // const getLoadingText = () => {
  //   if (sm) return 'Carregando...'
  //   return withSelectedWeight ? 'Incluindo na Coleta...' : 'Buscando Coleta...'
  // }

  return (
    <Container {...props}>
      <ButtonsContainer>
        <StyledButton
          variant="contained"
          loading={loading}
          disabled={loading || !withSelectedWeight}
          // loadingText={getLoadingText()}
          onClick={onClick}
          label={sm ? 'Incluir' : 'Incluir na Coleta'}
          className="add"
        />
        {!sm && (
          <StyledButton
            startIcon={<Print />}
            label="Imprimir"
            disabled={loading}
            variant="outlined"
            onClick={onPrint}
            className="print"
          />
        )}
      </ButtonsContainer>
      <Divider />
      <ContainerWeights>
        {!sm && (
          <div>
            <span>RESUMO DA COLETA</span>
            <span className="fill">{today()}</span>
          </div>
        )}
        <div>
          <WeightText>
            {translateWeightDescr('collect.collect', weight)}
          </WeightText>
          <WeightText className="fill">
            {translateWeightDescr('collect.selected', selectedWeight)}
          </WeightText>
          <WeightText>
            {translateWeightDescr('collect.total', weight + selectedWeight)}
          </WeightText>
        </div>
      </ContainerWeights>
    </Container>
  )
}

CollectStatus.propTypes = {
  onClick: PropTypes.func,
  actionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default CollectStatus
