import styled from 'styled-components'
import backgroundImage from 'assets/images/background.jpg'

export const NewConnectionSplashScreenContainer = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;

  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${(props) => props.theme.palette.primary.main};

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2rem;

    color: ${(props) => props.theme.palette.grey[300]};
    background-color: rgba(0, 0, 0, 0.85);
    min-height: 100vh;

    span {
      font-weight: 700;
      letter-spacing: 1px;
      font-size: 1rem;
    }
  }
`
