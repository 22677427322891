import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import * as medias from 'consts/medias'
import theme, { colors } from '../../theme'

export const StyledTextField = styled(TextField)`
  .MuiFormLabel-root:not(.Mui-disabled) {
    /* font-size: 12px; */
    color: ${colors.color500};
  }

  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(96, 125, 139, 0.2);
    }
  }

  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.palette.error.main};
  }

  label.MuiFormLabel-root.Mui-error {
    color: ${theme.palette.error.main};
  }

  .MuiInputBase-root {
    > input {
      font-size: 14px;

      &:focus ~ fieldset > legend {
      }
    }

    > svg {
      color: ${colors.color500};
      margin-right: 11px;
    }

    &::placeholder {
      color: ${colors.color100};
    }

    &:before {
    }

    &:after {
    }

    &.Mui-error:after {
    }

    &:hover {
      &:before {
      }
    }
  }

  label {
    &.Mui-focused {
    }
  }

  .MuiFormHelperText-contained {
    margin-right: 0;
    margin-left: 0;
    margin-top: 2px;
    margin-bottom: 4px;
  }

  p.Mui-error {
    font-size: 10px;
  }

  &.hidden {
    display: none;
  }

  &.large {
    .MuiFormLabel-root.Mui-disabled {
      color: ${colors.color500};
    }
    .MuiInputBase-root {
      > input {
        text-align: center;
        color: ${colors.color500};
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  @media ${medias.sm} {
    .MuiInputBase-root > input {
      font-size: 12px;
    }
  }
`
