import React, { Suspense, useState } from 'react'
import { useSelector } from 'react-redux'

import Content from 'components/Content'
import TextField from 'components/TextField'
import ComboboxBase from 'components/Combobox/Base'

import { ComboContainer } from './styles'
import Bulletin from './Bulletin'

import { BulletinsTable } from './Table'
import { useProfile } from 'hooks'

const NewBulletinForm = React.lazy(() => import('./NewBulletinForm'))

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

export const bulletinStatus = {
  ALL: '0',
  UNREAD: '1',
  READ: '2',
  BLOCK_ACCESS: '3',
}

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function ComboboxBulletinStatus(props) {
  return (
    <ComboContainer>
      <ComboboxBase
        name="status"
        label="STATUS"
        options={[
          { label: 'LIDOS', value: bulletinStatus.READ },
          { label: 'NÃO LIDOS', value: bulletinStatus.UNREAD },
          { label: 'TODOS', value: bulletinStatus.ALL },
        ]}
        renderInput={(provided) => (
          <TextField variant="outlined" {...provided} />
        )}
        disableClearable
        {...props}
      />
    </ComboContainer>
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function BulletinsPage() {
  const profile = useProfile()

  const unreadedBulletins = useSelector(
    (state) => state.comuns.bulletins.unreadedBulletins
  )

  const [bulletin, setBulletin] = useState(null)

  const [status, setStatus] = useState(() =>
    unreadedBulletins > 0 ? bulletinStatus.UNREAD : bulletinStatus.READ
  )

  function handleChangeStatus(e) {
    const newStatus = e.target.value
    setStatus(newStatus)
  }

  return (
    <Content
      title="Avisos"
      Controls={
        <ComboboxBulletinStatus value={status} onChange={handleChangeStatus} />
      }
    >
      <BulletinsTable status={status} onClickBulletin={setBulletin} />

      {profile.isMasterAdmin && (
        <Suspense fallback={null}>
          <NewBulletinForm />
        </Suspense>
      )}

      {bulletin && (
        <Bulletin bulletins={[bulletin]} onClose={() => setBulletin(null)} />
      )}
    </Content>
  )
}
