import React, { useRef, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as medias from 'consts/medias'
import { notEmpty, groupWithCustomer } from 'services/utils'
import { getItemId, createCollectForPrint } from 'services/collects'
import {
  getCollectRequest,
  removeCollectItemsRequest,
  cancelCollectPrint,
  finishCollectRequest,
} from 'store/modules/users/collections/reducer'
import { useMediaQuery } from '@material-ui/core'
import { Trash } from 'components/Icons'
import { Content, ErrorMessage } from 'components/index'
import {
  Container,
  Header,
  StyledCheckBox,
  StyledButton,
  CollectList,
} from './styles'

import FormEdit from './Form'
import TableCollect from 'components/Transportadora/TableCollect'
import CollectPrint from 'components/Transportadora/CollectPrint'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function CollectPage() {
  const sm = useMediaQuery(medias.sm)
  const dispatch = useDispatch()

  const loading = useSelector((state) => state.users.collections.loading)
  const collect = useSelector((state) => state.users.collections.active.collect)
  const errors = useSelector((state) => state.users.collections.errors)

  const collectForPrint = useSelector(
    (state) => state.users.collections.active.collectForPrint
  )

  const tablesRef = useRef({})

  const showExcludeButton = useMemo(() => {
    return !collect.every((item) => item.vpt_bloqueia_exclusao)
  }, [collect])

  useEffect(() => {
    dispatch(getCollectRequest())
  }, [dispatch])

  // Responsible effect for selecting the items with error in the collection
  useEffect(() => {
    function updateItemsWithError() {
      const collectIds = errors.map(getItemId).map((id) => String(id))

      Object.values(tablesRef.current).forEach((provided) => {
        provided.selectBy(
          (tableItem) => collectIds.includes(String(tableItem.id)),
          true,
          { withError: true }
        )
      })
    }

    updateItemsWithError()
  }, [errors])

  function handleFinish(values) {
    let params = { ...values }
    params.schedules = params.schedules || []

    dispatch(finishCollectRequest(params))
  }

  function handleSubmit(values) {
    handleFinish(values)
  }

  function removeSelectedItems() {
    let selected = []

    Object.values(tablesRef.current).forEach((provided) => {
      selected.push(provided.getSelectedItems())
    })

    dispatch(removeCollectItemsRequest(selected.flat()))
  }

  function selectAll(_, checked) {
    Object.values(tablesRef.current).forEach((methods) => {
      methods.selectAll(checked)
    })
  }

  function handleClose() {
    dispatch(cancelCollectPrint())
  }

  function Actions() {
    return (
      <Header>
        <StyledCheckBox onChange={selectAll} label="Selecionar Todos" />
        <ErrorMessage
          show={notEmpty(errors)}
          message="Alguns itens não possuem saldo suficiente"
        />

        {showExcludeButton && (
          <StyledButton
            color="primary"
            className="deleteButton"
            variant="contained"
            label={sm ? 'Excluir' : 'Excluir Selecionados'}
            endIcon={<Trash />}
            onClick={removeSelectedItems}
          />
        )}
      </Header>
    )
  }

  const hasItems = notEmpty(collect)
  const customersOrders = groupWithCustomer(collect)

  return (
    <Content
      title="Coleta em Andamento"
      loading={loading}
      Controls={hasItems && sm ? <Actions /> : null}
    >
      {!hasItems && (
        <Container>
          <span className="collect-empty-text">Não possui nenhum item</span>
        </Container>
      )}

      {hasItems && (
        <Container>
          <CollectList>
            {!sm && <Actions />}
            {customersOrders?.map((collectItems, index) => {
              const customerId = collectItems[0].cod_cliente

              return (
                <TableCollect
                  key={`customer-table-collect-${customerId}`}
                  data={collectItems}
                  getRef={(provided) => (tablesRef.current[index] = provided)}
                />
              )
            })}
          </CollectList>
          <FormEdit onSubmit={handleSubmit} />
        </Container>
      )}
      {collectForPrint && (
        <CollectPrint
          collect={createCollectForPrint(
            collectForPrint[0].id_coleta,
            collectForPrint
          )}
          open={Boolean(collectForPrint)}
          onClose={handleClose}
        />
      )}
    </Content>
  )
}
