import formatter from 'services/formatter'

export const isEmptyItem = (item) => Number(item.qtd_saldo_disp) === 0

export const isAvailable = (item) => !isEmptyItem(item)

export const inCollect = (item) => item.em_coleta !== '0'

export const createTableData = (newData, previousData) => {
  const isSelected = (item, previousItem) =>
    !previousItem || inCollect(item) ? false : previousItem.selected

  const orders = newData.flatMap((order) => {
    return order.itens.map((item) => {
      let orderItem = { ...item }

      const id = [item.cod_pedido, item.id_cia, item.id_itens]
      const rowId = String(id)
      const outletId = [item.cod_pedido, item.id_cia, item.cod_produto]
      const previousItem = previousData.find((i) => i.rowId === rowId)

      orderItem.id = id
      orderItem.rowId = rowId
      orderItem.outletId = outletId.join('')
      orderItem.dsc_abreviado = `${item.cod_produto} - ${item.dsc_abreviado}`
      orderItem.selected = isSelected(item, previousItem)
      orderItem.ped_seupedido = order.ped_seupedido
      orderItem.ped_data_emissao = formatter(
        order.ped_data_emissao
      ).toSimpleDate()
      orderItem.issued_at = `Emitido em ${orderItem.ped_data_emissao}`
      orderItem.ped_obs = order.obs.map((o) => o.ped_obs_transp).join('/')
      orderItem.cli_endereco_princ = order.cli_endereco_princ
      orderItem.cli_endereco = order.cli_endereco
      orderItem.id_agendamento = item.id_agendamento

      return orderItem
    })
  })

  return orders
}

export const createSummary = (data) => {
  const common = data[0]
  let summary = { ...common.customer_summary }
  summary.cli_endereco_princ = common.cli_endereco_princ
  summary.cli_cidade_princ = common.cli_cidade_princ
  summary.emp_telefone = common.emp_telefone
  summary.emp_telefone2 = common.emp_telefone2
  summary.trp_nome = common.trp_nome
  return summary
}

export const createCellStyle = ({
  isGroupedRow,
  columnName,
  rowData: orderItem,
}) => {
  switch (columnName) {
    case 'cod_pedido':
      return {
        whiteSpace: 'noWrap',
        paddingLeft: isGroupedRow ? (!isEmptyItem(orderItem) ? 50 : 25) : 16,
      }
    case 'dsc_abreviado':
      return {
        minWidth: '250px',
      }
    case 'ped_tipo_frete':
      return {
        width: '10px',
      }
    default:
      return null
  }
}
