import React from 'react'
import PropTypes from 'prop-types'

import CircularProgress from '@material-ui/core/CircularProgress'
import { StyledButton } from './styles'

/** @typedef {import('@material-ui/core/Button').ButtonProps} ButtonProps */

/**
 *
 * @param {ButtonProps & { loading: boolean }} props
 * @returns
 */
export function Button({ children, label, loading, ...props }) {
  const startIcon = loading ? (
    <CircularProgress color="inherit" size={18} />
  ) : (
    props.startIcon
  )

  const buttonDisable = loading || props.disabled

  return (
    <StyledButton {...props} disabled={buttonDisable} startIcon={startIcon}>
      {children || label}
    </StyledButton>
  )
}

const TranslationStringType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
])

Button.propTypes = {
  label: TranslationStringType,
  loading: PropTypes.bool,
}

Button.defaultProps = {
  loading: false,
}

export default Button
