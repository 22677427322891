import { createSlice } from '@reduxjs/toolkit'

/**
 * @typedef {({ vdt_data: string; vdt_tipo: string; })} AvailableDate
 *
 * @type {({ data: AvailableDate[], loading: boolean})}
 */
const initialState = {
  data: [],
  loading: false,
}

const availableDates = createSlice({
  name: 'availableDates',
  initialState,
  reducers: {
    getAvailableDatesRequest(state) {
      state.loading = true
    },
    getAvailableDatesReply(state, { payload }) {
      const { availableDates } = payload

      state.data = availableDates
      state.loading = false
    },
    //
    createAvailableDateRequest(state) {
      state.loading = true
    },
    createAvailableDateReply(state, { payload }) {
      const { newDate } = payload

      state.loading = false

      if (newDate) {
        state.data.push(newDate)
      }
    },
    //
    updateAvailableDateRequest(state) {
      state.loading = true
    },
    updateAvailableDateReply(state, { payload }) {
      const { updatedDate } = payload

      const foundedIndex = state.data.findIndex(
        (item) => item.vdt_data === updatedDate.vdt_data
      )

      state.loading = false
      state.data.splice(foundedIndex, 1, updatedDate)
    },
    //
    removeAvailableDateRequest(state, { payload }) {
      const { availableDateId } = payload

      const foundedIndex = state.data.findIndex(
        (item) => item.vdt_data === availableDateId
      )

      state.loading = true
      state.data.splice(foundedIndex, 1)
    },
    removeAvailableDateReply(state) {
      state.loading = false
    },
  },
})

export const availableDatesActions = availableDates.actions

export default availableDates.reducer
