import { all, takeLatest, call, put } from 'redux-saga/effects'

import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import handleErrors from 'services/handleErrors'

import {
  getCarriersRequest,
  getCarriersFailure,
  getCarriersSuccess,
} from './reducer'

function* getCarriers({ payload }) {
  try {
    const params = {}
    params.search = payload.search

    if (payload.showAll) {
      params.all = 1
    }

    const response = yield call(api.get, apiEndPoints.miscellaneous.carriers, {
      params,
    })

    yield put(getCarriersSuccess(response.data))
  } catch (error) {
    yield put(getCarriersFailure())
    handleErrors(error, 'Não foi possível buscar as transportadoras')
  }
}

export default all([takeLatest(getCarriersRequest.type, getCarriers)])
