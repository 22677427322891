import styled from 'styled-components'
import { colors } from 'theme'

import { Fab, Box } from '@material-ui/core'
import { Button, Paper as MainPaper } from '../../../components'

export const Paper = styled(MainPaper)`
  border: none;
  position: relative;
  z-index: 0;
`

export const SmallDeviseContent = styled.div`
  position: relative;
`

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;

  > * + * {
    margin-left: 5px;
  }

  .MuiAutocomplete-root {
    min-width: 150px;
  }
`

export const StyledButton = styled(Button)``

export const StyledFab = styled(Fab)`
  z-index: 1;
  position: fixed;
  bottom: 10px;
  right: 10px;

  width: 50px;
  height: 50px;

  background-color: ${colors.color500};

  .MuiSvgIcon-root {
    color: ${colors.white};
  }
`

export const ComboContainer = styled.div`
  min-width: 200px;
`

export const LoginButton = styled(Box)`
  cursor: pointer;
`
