import styled from 'styled-components'

import Button from '../Button'
import Paper from '../Paper'
import Dialog from '../Dialog'

export const StyledDialog = styled(Dialog)``

export const Container = styled(Paper)`
  padding: 20px 0;
  > div {
    font-size: 16px;
    line-height: 150%;
  }

  li {
    margin: 5px 16px;
    list-style: initial;
  }
`

export const StyledButton = styled(Button)``
