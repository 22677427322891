import { combineReducers } from 'redux'

import auth, { signOutRequest } from './auth/reducer'
import admin from './admin/reducer'
import user from './user/reducer'

import users from './users/reducer'
import comuns from './comuns/reducer'

import { logIsAsCompanyRequest } from './admin/companies/reducer'

const combineReducer = combineReducers({
  auth,
  admin,
  user,
  users,
  comuns,
})

const appReducer = (state, action) => {
  let storeState = state
  const type = action.type
  const persistAuth = [logIsAsCompanyRequest.type]

  if (type === signOutRequest.type) storeState = undefined
  if (persistAuth.includes(type)) storeState = { auth: { ...state.auth } }

  return combineReducer(storeState, action)
}

export default appReducer
