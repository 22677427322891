import { all, put, call, takeLatest } from 'redux-saga/effects'
import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import handleErrors from 'services/handleErrors'

import {
  getTradeReceivablesRequest,
  getTradeReceivablesSuccess,
  getTradeReceivablesFailure,
} from './reducer'

function* getTradeReceivables({ payload }) {
  try {
    const requestBody = { params: { ...payload } }
    const response = yield call(
      api.get,
      apiEndPoints.queries.tradeReceivables.root,
      requestBody
    )
    yield put(
      getTradeReceivablesSuccess({
        tradeReceivables: response.data,
        count: Number(response.headers.total),
      })
    )
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar as duplicatas!')
    yield put(getTradeReceivablesFailure())
  }
}

export default all([
  takeLatest(getTradeReceivablesRequest.type, getTradeReceivables),
])
