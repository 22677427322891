import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { format } from 'date-fns'
import { Yup as y } from '@/services/yup'
import { AVAILABLE_DATE_TYPES } from './utils'

import { Box, Button, styled } from '@material-ui/core'
import { Dialog } from '@/components'
import { Form, FormCombobox, FormDatePicker } from '@/components/CoForm'

const DangerButton = styled(Button)(({ theme }) => ({
  background: theme.palette.error.main,
  color: theme.palette.error.contrastText,

  '&:hover': {
    background: theme.palette.error.light,
  },
}))

// ---------------------------------------------

const NewAvailableDateSchema = y.object().shape({
  vdt_data: y.string().required('Informe a data'),
  vdt_tipo: y.string().required('Informe o status'),
})

export function AvailableDateDialog(props) {
  const { currentDate, open, onClose, onSave, onRemove, possibleDates } = props

  const formRef = useRef(null)

  const defaultValues = useMemo(() => {
    return {
      vdt_data: currentDate?.vdt_data,
      vdt_tipo: currentDate?.vdt_tipo || 'blocked',
    }
  }, [currentDate])

  const isClosed = currentDate === null
  const isEditing = !isClosed && currentDate?.vdt_data !== undefined

  const dateTypeOptions = Object.entries(AVAILABLE_DATE_TYPES).map(
    ([value, label]) => ({
      value,
      label,
    })
  )

  const shouldDisableDate = (date) => {
    const formattedDate = format(date, 'dd/MM/yyyy')
    return possibleDates?.includes(formattedDate)
  }

  const handleSubmit = (data) => {
    onSave(data, isEditing)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={isClosed ? '...' : isEditing ? 'Alterar Data' : 'Nova Data'}
      fullWidth
      maxWidth="xs"
    >
      <Form
        formRef={formRef}
        initialData={defaultValues}
        schema={NewAvailableDateSchema}
        onSubmit={handleSubmit}
      >
        <Box
          padding="1rem 0"
          display="flex"
          flexDirection="column"
          gridGap="1rem"
        >
          <FormDatePicker
            disabled={isEditing}
            variant="inline"
            disableToolbar
            fullWidth
            label="Data"
            placeholder="Selecione a data"
            name="vdt_data"
            size="medium"
            shouldDisableDate={shouldDisableDate}
          />

          <FormCombobox
            fullWidth
            label="Status"
            name="vdt_tipo"
            inputProps={{ size: 'medium', placeholder: 'Escolha o tipo' }}
            options={dateTypeOptions}
            disableClearable
          />

          <Box width="100%" display="flex" gridGap="1rem" marginTop="1rem">
            <Button variant="outlined" color="default" onClick={onClose}>
              Cancelar
            </Button>

            {isEditing && (
              <DangerButton
                disableElevation
                variant="contained"
                color="default"
                onClick={onRemove}
              >
                Remover
              </DangerButton>
            )}

            <Box flexGrow={1} display="flex" justifyContent="flex-end">
              <Button type="submit" variant="contained" color="primary">
                Salvar
              </Button>
            </Box>
          </Box>
        </Box>
      </Form>
    </Dialog>
  )
}

AvailableDateDialog.propTypes = {
  currentDate: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  //
  onRemove: PropTypes.func,
  onSave: PropTypes.func,
}
