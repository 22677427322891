import React, { useRef, useMemo, useEffect } from 'react'

import { Form, FormTextField } from '@/components/CoForm'
import { Box, Dialog, DialogActions, DialogContent } from '@material-ui/core'

import { cpf, placa } from '@/consts/regexp'
import { toMask } from '@/services/masks'
import { Button } from '@/components'
import { StyledDialogTitle } from '@/components/Dialog/styles'

import { handleValidationError, Yup as y } from '@/services/yup'

import { useDispatch, useSelector } from 'react-redux'
import {
  cancelEditCollect,
  updateCollectRequest,
} from '@/store/modules/users/collections/reducer'

const EditCollectSchema = y.object().shape({
  vpt_placa: y.string().required(),
  vpt_motorista: y.string().required(),
  vpt_cod_motorista: y.string().required(),
})

// -----------------------------------------------------------

export function CollectQuickFormEdit() {
  const activeCollect = useSelector(
    (state) => state.users.collections.active.collectParams
  )

  const isSubmitting = useSelector((state) => state.users.collections.loading)

  const dispatch = useDispatch()

  function handleClose() {
    dispatch(cancelEditCollect())
  }

  const formRef = useRef(null)

  const defaultValues = useMemo(
    () => ({
      id_coleta: activeCollect?.id_coleta || '',
      vpt_placa: activeCollect?.vpt_placa || '',
      vpt_motorista: activeCollect?.vpt_motorista || '',
      vpt_cod_motorista: activeCollect?.vpt_cod_motorista || '',
    }),
    [activeCollect]
  )

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setData(defaultValues)
    }
  }, [defaultValues])

  function handleSubmitButton() {
    formRef.current.setErrors({})

    const data = formRef.current.getValues()
    handleSubmit(data)
  }

  async function handleSubmit(data) {
    const formValidator = handleValidationError(formRef.current)

    EditCollectSchema.validate(data, { abortEarly: false })
      .then((data) => {
        dispatch(updateCollectRequest(data))
      })
      .catch((errors) => {
        formValidator(errors)
      })
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={Boolean(activeCollect.isEditing)}>
      <StyledDialogTitle>Alterar informações da coleta</StyledDialogTitle>

      <DialogContent>
        <Form
          formRef={formRef}
          initialData={defaultValues}
          onSubmit={handleSubmit}
          schema={EditCollectSchema}
        >
          <Box padding="1rem 0" display="grid" gridGap="1rem">
            <FormTextField
              fullWidth
              size="small"
              name="id_coleta"
              label="Coleta"
              variant="outlined"
              disabled
              onChange={() => {}}
            />

            <FormTextField
              fullWidth
              size="small"
              name="vpt_placa"
              label="Placa"
              textMaskProps={toMask(placa.array)}
            />

            <FormTextField
              fullWidth
              size="small"
              label="CPF do Motorista"
              name="vpt_cod_motorista"
              textMaskProps={toMask(cpf.array)}
            />

            <FormTextField
              fullWidth
              size="small"
              name="vpt_motorista"
              label="Motorista"
              placeholder="Qual o nome do motorista?"
            />
          </Box>
        </Form>
      </DialogContent>

      <DialogActions>
        <Button
          type="button"
          variant="outlined"
          color="primary"
          onClick={handleClose}
        >
          Cancelar
        </Button>

        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleSubmitButton}
          loading={isSubmitting}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
