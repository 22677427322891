import PropTypes from 'prop-types'
import { equals, isEmpty, merge, sum } from 'ramda'
import React, { useMemo } from 'react'

import { Box, Tooltip, Typography, useMediaQuery } from '@material-ui/core'

import { translate } from '@/_i18n'
import { isSelectableOrderItem } from '@/_mock/orders'
import * as medias from '@/consts/medias'
import formatter from '@/services/formatter'
import { createGroupHeader } from '@/services/utils'

import {
  createCellStyle,
  createSummary,
  createTableData,
  inCollect,
  isAvailable,
  isEmptyItem,
} from './utils'

import MultiSelectionTable from '../../MultiSelectionTable'
import { RenderTableRow as TableRow } from '../../Table/TableRow'
import { TableHeader } from '../../Table/TableHeader'
import { confirmationDialog } from '../../ConfirmationDialog'
import { StyledCheckBox } from '../../Table'

import { ChipArea, Container, StyledChip, TruckIcon } from './styles'
import { OrderTypeBadge } from '../OrderTypeBadge'

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function CollectIcon({ orderItem }) {
  switch (orderItem.em_coleta) {
    case '1':
      return <TruckIcon />
    case '2':
      return (
        <Tooltip
          arrow
          title={orderItem.em_coleta_usuario}
          placement="right-end"
        >
          <TruckIcon className="emColetaUsuario" />
        </Tooltip>
      )

    default:
      return null
  }
}

const outletContentMessage = (withUnavailableOutlets) => (
  <>
    <Typography component="p" variant="body1">
      Atenção, caso não inclua o item(s), posteriormente ele poderá ser recebido
      em tonalidade diferente.
    </Typography>
    {withUnavailableOutlets && (
      <Typography component="p" variant="body2">
        - Existe item(s) sem saldo, posteriormente ele poderá ser recebido em
        tonalidade diferente.
      </Typography>
    )}
  </>
)

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function TableOrders({
  data,
  onUpdate,
  getRef,
  getHeaderData,
  ...props
}) {
  const orders = useMemo(() => {
    return data?.map((order) => ({
      ...order,
      itens: order.itens?.map((item) => ({
        ...item,
        cia_descricao_carreg: order.cia_descricao_carreg,
        qtd_pallet_disp: parseInt(item.qtd_pallet_disp),
        qtd_caixas_disp: parseInt(item.qtd_caixas_disp),
        qtd_pallet_pedido: parseInt(item.qtd_pallet_pedido),
        qtd_caixas_pedido: parseInt(item.qtd_caixas_pedido),
      })),
    }))
  }, [data])

  const sm = useMediaQuery(medias.sm)
  const print = useMediaQuery(medias.print)

  const summary = createSummary(data)

  const createColumns = (methods) => {
    const { onSelectRow } = methods

    const renderOrderItemSelection = ({ rowData: orderItem }) => {
      if (!orderItem.cod_pedido) return null

      const checked = orderItem.selected
      const outletId = orderItem.outletId

      const handleSelectOrderItem = async (_, checked) => {
        async function handleUnselectedOutlets() {
          if (checked) {
            const isUnselectedOutlet = (item) =>
              equals(item.outletId, outletId) && !item.selected

            const unselectedOutlets = methods.filterBy(isUnselectedOutlet)
            if (unselectedOutlets.length === 1) return

            const unavailableOutlets = unselectedOutlets.filter(
              (i) => isEmptyItem(i) && !inCollect(i)
            )
            const withUnavailableOutlets = !isEmpty(unavailableOutlets)

            const tilte = `Incluir montagem de carga ${orderItem.dsc_abreviado} ?`
            const message = outletContentMessage(withUnavailableOutlets)

            if (await confirmationDialog.open(tilte, message))
              methods.select(unselectedOutlets.filter(isAvailable), checked)

            confirmationDialog.close()
          }
        }

        onSelectRow(orderItem)(_, checked)
        await handleUnselectedOutlets()
      }

      const showOrderItemSelection =
        isSelectableOrderItem(orderItem.tipo) && isAvailable(orderItem)

      return (
        <Box display="flex" alignItems="center">
          {showOrderItemSelection && (
            <StyledCheckBox
              checked={checked}
              value={checked}
              onChange={handleSelectOrderItem}
            />
          )}

          <CollectIcon orderItem={orderItem} />
        </Box>
      )
    }

    let columns = [
      {
        title: translate('orders.table.order'),
        field: 'cod_pedido',
        renderCell: renderOrderItemSelection,
      },
      {
        title: translate('orders.table.product'),
        field: 'dsc_abreviado',
      },
      {
        title: translate('orders.table.company'),
        field: 'cia_descricao_carreg',
      },
      {
        title: translate('orders.table.quantity'),
        field: 'qtd_saldo_disp',
        align: 'right',
        cellFormat: 'decimal',
      },
      {
        title: translate('orders.table.weight'),
        field: 'peso_bru_disp',
        align: 'right',
        cellFormat: 'decimal',
      },
      {
        title: translate('orders.table.pallets'),
        field: 'qtd_pallet_disp',
        align: 'right',
      },
      {
        title: translate('orders.table.boxes'),
        field: 'qtd_caixas_disp',
        align: 'right',
      },
      {
        title: translate('orders.table.delivery'),
        field: 'data_entrega',
      },
      {
        title: translate('orders.table.freight'),
        field: 'ped_tipo_frete',
      },
      {
        title: translate('orders.table.representative'),
        field: 'rep_nome',
      },
      {
        title: translate('orders.table.quantity'),
        field: 'qtd_saldo_pedido',
        align: 'right',
        cellFormat: 'decimal',
      },
      {
        title: translate('orders.table.weight'),
        field: 'peso_bru_pedido',
        align: 'right',
        cellFormat: 'decimal',
      },
      {
        title: translate('orders.table.pallets'),
        field: 'qtd_pallet_pedido',
        align: 'right',
      },
      {
        title: translate('orders.table.boxes'),
        field: 'qtd_caixas_pedido',
        align: 'right',
      },
      // {
      //   title: 'TIPO',
      //   field: 'tipo',
      //   align: 'right',
      //   renderCell: ({ cellValue: type }) => ORDER_TYPES_LABELS[type] || type,
      // },
    ]

    if (print) {
      const commonFields = [
        'cod_pedido',
        'ped_tipo_frete',
        'rep_nome',
        'data_entrega',
        'id_agendamento',
      ]
      columns = columns.filter((c) => !commonFields.includes(c.field))
    }

    return columns
  }

  const createComponents = (provided) => {
    let components = {}

    const {
      onSelectTable,
      getTableChecked,
      getGroupChecked,
      onSelectGroup,
      getShowGroupSelector,
    } = provided

    const Header = () => {
      let checkboxProps = null
      const showCheckbox = getShowGroupSelector()
      const helperText = [summary.emp_telefone, summary.emp_telefone2]
        .filter((text) => text?.trim()?.length > 0)
        .join(' / ')
      const bodyData = [
        summary.cli_endereco_princ,
        summary.cli_cidade_princ,
        formatter(summary.cod_cliente).toCNPJorCPF(),
      ]

      if (showCheckbox) {
        const checked = getTableChecked()
        checkboxProps = {
          checked,
          onChange: onSelectTable,
        }
      }

      // ================
      // ==== HEADER ====
      // ================

      const tableHeaderDefaultProps = {
        title: summary.cli_nome,
        helperText,
        bodyData,
      }

      const tableHeaderProps = getHeaderData
        ? merge(tableHeaderDefaultProps, getHeaderData(summary))
        : tableHeaderDefaultProps

      return <TableHeader checkboxProps={checkboxProps} {...tableHeaderProps} />
    }

    const Group = ({ rowData: orderItem, ...props }) => {
      const columns = props.columns
      const checked = getGroupChecked(orderItem)

      const groupColumns = [
        { field: 'order' },
        { field: 'date', colSpan: print ? 0 : columns.length - 1 },
      ]

      const helperRows = [
        { label: 'Entregar em', text: orderItem.cli_endereco },
        {
          label: 'Obs',
          text: orderItem.ped_obs,
          className: 'Chip-obs',
        },
      ]

      if (print)
        groupColumns.push({
          field: 'order_descr',
          colSpan: columns.length - 2,
        })

      return (
        <>
          <TableRow
            {...props}
            cellStyle={() => ({ width: 100 })}
            columns={groupColumns}
            rowData={{
              ...orderItem,
              date: sm ? orderItem.ped_data_emissao : orderItem.issued_at,
              order_descr: [
                orderItem.ped_tipo_frete,
                orderItem.rep_nome,
                orderItem.data_entrega,
              ].join('/'),
              order: (
                <Box display="flex" gridGap="1rem" alignItems="center">
                  <span>
                    {getShowGroupSelector(orderItem) && (
                      <StyledCheckBox
                        checked={checked}
                        value={checked}
                        onChange={onSelectGroup(orderItem)}
                        disabled={!orderItem}
                      />
                    )}

                    {orderItem.cod_pedido}
                  </span>

                  <OrderTypeBadge variant={orderItem.tipo} />
                </Box>
              ),
            }}
          />
          {helperRows.map(({ label, text, className }, index) => {
            let helperColumns = []
            helperColumns[0] = columns[0]
            helperColumns[1] = {
              ...columns[1],
              colSpan: 15,
            }

            return text ? (
              <TableRow
                {...props}
                key={index}
                columns={helperColumns}
                isGroupRow={false}
                rowData={{
                  dsc_abreviado: (
                    <ChipArea>
                      <StyledChip className={className} label={label} />
                      <span>{text}</span>
                    </ChipArea>
                  ),
                }}
              />
            ) : null
          })}
        </>
      )
    }

    const Footer = (props) => {
      const tableData = provided.tableData
      const sumOf = (field) => sum([...tableData.map((d) => d[field])])

      return (
        <TableRow
          {...props}
          rowData={{
            cod_pedido: 'TOTAL',
            qtd_saldo_disp: sumOf('qtd_saldo_disp'),
            peso_bru_disp: sumOf('peso_bru_disp'),
            qtd_pallet_disp: sumOf('qtd_pallet_disp'),
            qtd_caixas_disp: sumOf('qtd_caixas_disp'),
            qtd_saldo_pedido: sumOf('qtd_saldo_pedido'),
            peso_bru_pedido: sumOf('peso_bru_pedido'),
            qtd_pallet_pedido: sumOf('qtd_pallet_pedido'),
            qtd_caixas_pedido: sumOf('qtd_caixas_pedido'),
          }}
        />
      )
    }

    components.Header = Header
    components.Group = Group
    components.Footer = Footer
    return components
  }

  return (
    <Container {...props}>
      <MultiSelectionTable
        groupBy="cod_pedido"
        data={orders}
        columns={createColumns}
        components={createComponents}
        isAvailable={isAvailable}
        TableProps={{
          groupHeader: print
            ? [createGroupHeader('DISPONÍVEL/PEDIDO', 4, 8)]
            : [
                createGroupHeader('DISPONÍVEL', 4, 4),
                createGroupHeader('PEDIDO', 4, 4),
              ],
          cellStyle: createCellStyle,
        }}
        getRef={getRef}
        onUpdate={onUpdate}
        onRefresh={createTableData}
      />
    </Container>
  )
}

TableOrders.propTypes = {
  getHeaderData: PropTypes.func,
}

export default TableOrders
