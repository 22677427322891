import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { colors } from '../../theme'
import backgroundImage from '../../assets/images/background.jpg'

import { Button } from '../../components'
import { sm } from '../../consts/medias'
import { Form } from 'components/CoForm'

export const Container = styled.div`
  position: relative;
  height: 100vh;

  display: flex;
  justify-content: flex-end;

  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${(props) => props.theme.palette.primary.main};
`

const HocLink = ({ className, ...props }) => (
  <div className={className}>
    <Link {...props} />
  </div>
)

export const StyledLink = styled(HocLink)`
  font-size: 13px;
  margin-top: 8px;
`

export const StyledForm = styled(Form)`
  flex: 1;
  max-width: max(600px, 45%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.85);

  .MuiFormControl-root {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 4px;

    .MuiSvgIcon-root,
    .MuiInputBase-input {
      color: #fff;
    }
  }

  .MuiTypography-body1 {
    color: ${colors.color300};
  }

  .subtitle {
    text-align: center;
    line-height: 150%;
  }

  .subtitle,
  > footer small {
    color: ${colors.color300};
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.2px;
  }

  > main {
    width: 60%;

    header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;

      img {
        width: 100%;
        max-width: 300px;
        margin: 1rem 0;
      }
    }

    .fields {
      display: flex;
      flex-direction: column;
      margin: 8px 0;

      label {
        font-size: 13px;
      }

      .MuiFilledInput-root,
      .MuiInputBase-input:-webkit-autofill {
        background-color: transparent !important;
      }

      .MuiFormControl-root,
      .MuiAutocomplete-root {
        flex: 1 1 auto;
        margin: 8px 0;
      }

      .hidden {
        display: none;
      }
    }

    .links {
      ${StyledLink} {
        &:nth-child(2) {
          margin-top: 0px;
        }
      }
    }
  }

  footer {
    position: absolute;
    bottom: 0;

    margin-top: 60px;
    padding: 8px;

    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  @media ${sm} {
    padding: 24px;

    .subtitle,
    footer {
      display: none;
    }

    > main {
      width: 100%;
      header img {
        margin: 0;
        margin-bottom: 32px;
      }
    }
  }
`

export const SubmitButton = styled(Button)`
  width: 100%;
  border-radius: 8px;
  margin: 1rem auto;

  &.MuiButton-contained.Mui-disabled {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`

export const PasswordControl = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span.MuiTypography-root,
  a {
    font-size: 13px;
  }

  .MuiSvgIcon-root {
    width: 0.8em;
    height: 0.8em;
  }
`
