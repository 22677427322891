import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Build, LocalShipping, Person } from '@material-ui/icons'
import {
  useMediaQuery,
  useTheme,
  Box,
  Tooltip,
  Avatar,
  IconButton,
} from '@material-ui/core'

import * as medias from 'consts/medias'
import { company, inviteStatus as inviteStatusType } from 'consts/types'
import { applyFilters, isDeveloper } from 'services/utils'
import { applyScope, applyScopeInvite } from 'services/scope'
import { useSortData, usePagination } from 'hooks/index'
import {
  getCompaniesRequest,
  sendInviteRequest,
  removeCompanyRequest,
  logIsAsCompanyRequest,
} from 'store/modules/admin/companies/reducer'

import { Add, Mail, Edit, Delete, Signout } from 'components/Icons'
import {
  TablePagination,
  Content,
  Table,
  ChipStatus,
  confirmationDialog,
} from 'components/index'

import ComboboxUserStatus from 'components/Combobox/UserStatus'
import ComboboxInviteStatus from 'components/Combobox/InviteStatus'
import ComboboxCompanyTypes from 'components/Combobox/CompanyTypes'

import FormEdit from './FormEdit'
import Filter from './Filter'

import { StyledButton, LoginButton, Paper } from './styles'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const INITIAL_SORT = 'company.emp_razao_social'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function AdminCompaniesPage() {
  const dispatch = useDispatch()
  const theme = useTheme()
  const sm = useMediaQuery(medias.sm)

  const loading = useSelector((state) => state.admin.companies.loading)
  const webCompanies = useSelector(
    (state) => state.admin.companies.webCompanies
  )

  const [companyType, setCompanyType] = useState('')
  const [status, setStatus] = useState('')
  const [inviteStatus, setinviteStatus] = useState('')

  const [selected, setSelected] = useState(null)
  const [selectedFilters, setSelectedFilters] = useState([])

  const { currentSort, sortData, onSortChange } = useSortData({
    initialField: INITIAL_SORT,
  })

  const {
    calculateNewPaginatorData,
    onChangePage,
    onChangeRowsPerPage,
    reset,
    rowsPerPage,
    page,
  } = usePagination()

  useEffect(() => {
    dispatch(getCompaniesRequest())
  }, [dispatch])

  function handleDisplayForAdd() {
    setSelected({})
  }

  function handleDisplayForEdit(webCompany) {
    setSelected(webCompany)
  }

  function handleClose() {
    setSelected(null)
  }

  useEffect(() => {
    handleClose()
  }, [webCompanies])

  const handleAdminAccess = (webCompany) => async () => {
    const confirm = await confirmationDialog.open(
      'Personificar Empresa',
      `Deseja acessar o portal como ${webCompany.company.emp_razao_social} ?`
    )

    if (confirm) {
      dispatch(logIsAsCompanyRequest(webCompany))
    }

    confirmationDialog.close()
  }

  const handleSendInvite = (webCompany) => () =>
    dispatch(sendInviteRequest(webCompany))

  const handleDelete = (webCompany) => () =>
    dispatch(removeCompanyRequest(webCompany))

  function renderEmail({ cellValue, rowData: webCompany }) {
    return (
      <Box display="flex" alignItems="center">
        <span>{cellValue}</span>
        <Tooltip title={`Acessar como ${cellValue}`}>
          <LoginButton
            component={Signout}
            paddingLeft={1}
            onClick={handleAdminAccess(webCompany)}
          />
        </Tooltip>
      </Box>
    )
  }

  function renderSendInvite({ rowData: webCompany }) {
    const webUser = webCompany.admin_web_user

    if (!webUser || webUser?.weu_tipo === company.ECOSIS) {
      return null
    }

    const accepted = webUser.invite_status === inviteStatusType.ACCEPTED

    const title =
      webUser.invite_status === inviteStatusType.NOT_INVITED
        ? 'Enviar Convite'
        : 'Reenviar Convite'

    return (
      <IconButton
        size="small"
        onClick={handleSendInvite(webCompany)}
        disabled={accepted}
      >
        <Mail
          className={webUser.invite_status}
          fontSize="small"
          title={title}
        />
      </IconButton>
    )
  }

  function renderEdit({ rowData: webCompany }) {
    return (
      <IconButton size="small" onClick={() => handleDisplayForEdit(webCompany)}>
        <Edit />
      </IconButton>
    )
  }

  function renderDelete({ rowData: webCompany }) {
    const webUser = webCompany.admin_web_user
    if (webUser?.invite_status === inviteStatusType.NOT_INVITED)
      return (
        <IconButton size="small" onClick={handleDelete(webCompany)}>
          <Delete fontSize="small" />
        </IconButton>
      )
  }

  function renderCompanyStatus({ cellValue }) {
    const isActive = cellValue === 'active'
    const color = theme.palette[isActive ? 'success' : 'error'].main
    return (
      <ChipStatus label={isActive ? 'Ativo' : 'Inativo'} background={color} />
    )
  }

  const handleChangeScope = (scopeSetter) => (e) => {
    const newScopeValue = e.target.value
    scopeSetter(newScopeValue)
    setSelectedFilters([])
    reset()
  }

  const applyScopes = (collection) => {
    // Filtrar pelo status do convite
    let newCollection = applyScopeInvite(
      inviteStatus,
      (company) =>
        isDeveloper(company.wee_tipo)
          ? null
          : company.admin_web_user?.invite_status,
      collection
    )

    // Filtrar pelo status da empresa
    newCollection = applyScope(
      status,
      (company) => company.wee_ativo,
      newCollection
    )

    // Filtrar por tipo de empresa
    newCollection = applyScope(
      companyType,
      (company) => company.wee_tipo,
      newCollection
    )

    return newCollection
  }

  const scopedWebCompanies = applyScopes(
    applyFilters(selectedFilters, webCompanies)
  )

  // table data, paging and sorting
  const { currentPageRecords } = calculateNewPaginatorData(
    sortData(scopedWebCompanies)
  )

  return (
    <Content
      title="Empresas"
      loading={loading}
      SideComponent={
        <Filter
          data={scopedWebCompanies}
          loading={loading}
          selectedFilters={selectedFilters}
          onFiltering={setSelectedFilters}
          reset={reset}
        />
      }
      Controls={
        <>
          <ComboboxCompanyTypes
            value={companyType}
            onChange={handleChangeScope(setCompanyType)}
          />
          <ComboboxUserStatus
            value={status}
            onChange={handleChangeScope(setStatus)}
          />
          <ComboboxInviteStatus
            value={inviteStatus}
            onChange={handleChangeScope(setinviteStatus)}
          />

          {!sm && (
            <StyledButton
              color="primary"
              label="Adicionar"
              variant="contained"
              onClick={handleDisplayForAdd}
              endIcon={<Add />}
            />
          )}
        </>
      }
    >
      <Paper className="border-none">
        <Table
          size="medium"
          currentSort={currentSort}
          cellStyle={({ columnName }) =>
            columnName === 'wee_tipo'
              ? {
                  maxWidth: '40px',
                }
              : {}
          }
          onSortChange={onSortChange}
          data={currentPageRecords}
          columns={[
            {
              title: '',
              field: 'wee_tipo',
              align: 'center',
              renderCell: renderCompanyType,
            },
            { title: 'EMPRESA', field: 'company.emp_razao_social' },
            {
              title: 'EMAIL',
              field: 'wee_email',
              renderCell: renderEmail,
            },
            {
              title: 'CNPJ',
              field: 'cod_empresa',
              cellFormat: 'document',
            },
            { title: 'TELEFONE', field: 'admin_web_user.weu_telefone' },
            { role: 'button', renderCell: renderSendInvite },
            { role: 'button', renderCell: renderEdit },
            { role: 'button', renderCell: renderDelete },
            {
              title: 'STATUS',
              field: 'wee_ativo',
              role: 'status',
              renderCell: renderCompanyStatus,
            },
          ]}
        />
        <TablePagination
          count={scopedWebCompanies.length}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
        />
      </Paper>
      {selected && (
        <FormEdit
          open={Boolean(selected)}
          current={selected}
          onClose={handleClose}
        />
      )}
    </Content>
  )
}

function renderCompanyType({ cellValue: type }) {
  const props = () => {
    switch (type) {
      case company.CLIENTE:
        return {
          label: <Person fontSize="small" />,
          color: 'cornflowerblue',
        }
      case company.ECOSIS:
      case company.ADMIN:
        return { label: <Build fontSize="small" />, color: '#000c' }
      case company.TRANSPORTADORA:
      default:
        return {
          label: <LocalShipping fontSize="small" />,
          color: 'darkseagreen',
        }
    }
  }

  const { label, color } = props()

  return (
    <Avatar
      style={{
        backgroundColor: color,
        height: '30px',
        width: '30px',
        fontSize: '12px',
        margin: '0 auto',
      }}
    >
      {label}
    </Avatar>
  )
}

export default AdminCompaniesPage
