import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { colors } from 'theme'

import Button from 'components/Button'
import { textEditorStyles } from 'lib/TextEditor/styles'

export const BulletinContainer = styled.div`
  margin-top: 1rem;

  .MuiStepper-root {
    overflow: auto;
  }

  .loading__text {
    height: 20px;
  }
`

export const MessageContainer = styled.div`
  ${textEditorStyles}

  min-height: 450px;
  padding: 0.5rem 0;
  border-top: ${(props) =>
    props.variant === 'multiple' ? `1px solid ${colors.color100}` : 'none'};
`

export const AttachmentsContainer = styled.div`
  border-top: 1px solid ${colors.color100};
  padding: 0.5rem 0;

  > span {
    font-size: 12px;
    color: ${colors.color400};
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`

export const CloseButton = styled(Button)``

export const Actions = styled(Box)`
  width: 100%;
  display: flex;
`
