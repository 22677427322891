import '@/lib/date-fns'

import React from 'react'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { I18nProvider } from './_i18n'
import MuiPickersUtilsProvider from 'components/MaterialUiPickersUtilsProvider'
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core'
import GlobalStyle from './styles/global'
import { ThemeProvider } from 'styled-components'
import { ToastContainer, Slide } from 'react-toastify'

import { persistor, store } from './store'
import theme from './theme'
import history from './services/history'

// import PortalAdapter from './components/PortalAdapter'
import SignOutChecker from './components/SignOutChecker'
import ConfirmationDialog from './components/ConfirmationDialog'
import Routes from './routes/routes'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nProvider>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <CssBaseline />
              <MuiPickersUtilsProvider>
                {/* <PortalAdapter /> */}
                <SignOutChecker />
                <ConfirmationDialog />
                <Router history={history}>
                  <Routes />
                </Router>
                <ToastContainer
                  autoClose={5000}
                  transition={Slide}
                  position="top-right"
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </I18nProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
