import React, { useRef, useEffect, useState } from 'react'
import { slice, map, prop, isEmpty, sum } from 'ramda'
import { useSelector, useDispatch } from 'react-redux'

import * as medias from 'consts/medias'
import { colors } from 'theme'
import { groupWithCustomer } from 'services/utils'
import { getOrdersRequest } from 'store/modules/users/ordersForCollect/reducer'
import {
  createCollectRequest,
  updateSelectedWeight,
} from 'store/modules/users/collections/reducer'

import { useMediaQuery } from '@material-ui/core'
import { Content, CollectStatus } from 'components'
import { LoaderArea } from './styles'

import ScaleLoader from 'react-spinners/ScaleLoader'
import InfiniteScroll from 'react-infinite-scroll-component'
import TableCustomerOrders from 'components/Transportadora/TableOrder'
import Filter from './Filter'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

const LIMITER = 100

// --------------- ℍ𝕖𝕝𝕡𝕖𝕣𝕤 ---------------

const sumWeight = (list) => sum(map(prop('peso_bru'), list))

const getSelectedItems = (tables) => {
  let selectedItems = []

  tables.forEach((table) => {
    selectedItems.push(table.getSelectedItems())
  })

  return selectedItems.flat()
}

const removeTablesSelection = (tables) =>
  tables.forEach((table) => table.selectAll(false))

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function OrdersPage() {
  const sm = useMediaQuery(medias.sm)
  const dispatch = useDispatch()

  const tablesRef = useRef([])
  const [tableLimiter, setTableLimiter] = useState(LIMITER)

  const orders = useSelector(
    (state) => state.users.ordersForCollect.filteredOrders
  )
  const selectedFilters = useSelector(
    (state) => state.users.ordersForCollect.selectedFilters
  )

  const loading = useSelector((state) => state.users.ordersForCollect.loading)
  const records = groupWithCustomer([...orders])

  useEffect(() => {
    dispatch(getOrdersRequest())
  }, [dispatch])

  useEffect(() => {
    // effect responsible for resetting the states that depend on the requests, after their eventual filtering
    removeTablesSelection(tablesRef.current)
    setTableLimiter(LIMITER)
    dispatch(updateSelectedWeight())
  }, [dispatch, orders])

  const handlePrint = () => {
    setTableLimiter(records.length)
    setTimeout(() => {
      window.print()
    }, 0)
  }

  const handleAddItemsInCollect = () => {
    const selectedItems = getSelectedItems(tablesRef.current)

    const collects = selectedItems.map((item) => {
      const ownerOrder = orders.find(
        (order) =>
          String([order.cod_pedido, order.id_cia]) ===
          String([item.cod_pedido, item.id_cia])
      )

      return {
        cod_pedido: item.cod_pedido,
        id_cia: item.id_cia,
        id_itens: item.id_itens,
        id_coleta: item.id_coleta,
        id_agendamento: item.id_agendamento,

        qtd_saldo_disp: item.qtd_saldo_disp,
        itped_data_entrega: item.itped_data_entrega,
        peso_bru_disp: item.peso_bru_disp,
        prd_m2_caixa: item.prd_m2_caixa,
        itped_cx_pallet: item.itped_cx_pallet,
        qtd_pallet_disp: item.qtd_pallet_disp,
        qtd_caixas_disp: item.qtd_caixas_disp,
        data_entrega: item.data_entrega,

        rep_nome: item.rep_nome,
        cod_representante: ownerOrder?.cod_representante,
        cod_cliente: ownerOrder?.cod_cliente,
        cli_nome: ownerOrder?.cli_nome,
        bairro_cliente: ownerOrder?.bairro_cliente,
        cli_cidade: ownerOrder?.cli_cidade,
        cli_endereco_princ: item.cli_endereco_princ,
        dsc_uf: ownerOrder?.dsc_uf,
        cia_descricao_carreg: item.cia_descricao_carreg,
        ped_data_emissao: item.ped_data_emissao,

        dsc_abreviado: item.dsc_abreviado,
        cod_produto: item.cod_produto,
        id_un_medidas: item.id_un_medidas,
        tipo: item.tipo,
        homecenter: ownerOrder?.homecenter,
      }
    })

    dispatch(createCollectRequest(collects))
  }

  const handleUpdateWeight = (updatedItens) => {
    const added = sumWeight(updatedItens.filter((item) => item.selected))
    const removed = sumWeight(updatedItens.filter((item) => !item.selected))

    dispatch(
      updateSelectedWeight({
        added,
        removed,
      })
    )
  }

  const fetchMoreData = () => {
    setTimeout(() => {
      setTableLimiter((limiter) => limiter + LIMITER)
    }, 200)
  }

  const calculateCurrentRecords = () =>
    records.length < LIMITER ? records : slice(0, tableLimiter, records)

  const currentRecords = calculateCurrentRecords()

  return (
    <Content
      title={sm ? 'Pedidos' : null}
      containerId="carrier-orders-container"
      loading={loading}
      sideBarStart={!isEmpty(selectedFilters)}
      SideComponent={<Filter selectedFilters={selectedFilters} />}
      HeaderComponent={
        <CollectStatus
          onPrint={handlePrint}
          onClick={handleAddItemsInCollect}
        />
      }
    >
      <InfiniteScroll
        dataLength={currentRecords.length}
        hasMore={!loading && currentRecords.length < records.length}
        next={fetchMoreData}
        scrollableTarget="carrier-orders-container"
        className="printable"
        scrollThreshold={1}
        loader={
          <LoaderArea>
            <ScaleLoader color={colors.color500} />
            <small>Carregando mais pedidos...</small>
          </LoaderArea>
        }
      >
        {currentRecords.map((data, index) => (
          <TableCustomerOrders
            key={index}
            data={[...data]}
            onUpdate={handleUpdateWeight}
            getRef={(table) => (tablesRef.current[index] = table)}
          />
        ))}
      </InfiniteScroll>
    </Content>
  )
}
