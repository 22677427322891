import styled from 'styled-components'
import * as medias from 'consts/medias'
import { colors } from 'theme'

import Button from 'components/Button'
import { FormImageInput } from 'components/CoForm'
import { TabPanel } from 'components/TabNavigation'

export const Container = styled.div`
  padding: 1rem 0;

  display: flex;
  flex-direction: column;

  .MuiTabs-flexContainer {
    justify-content: center;
  }

  .MuiTab-root {
    flex: 1;
  }
`

export const StyledPanel = styled(TabPanel)`
  height: auto;
  max-height: 400px;
`

export const Avatar = styled(FormImageInput)`
  background-color: ${colors.color200};

  width: 80px;
  height: 80px;
  font-size: 2rem;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
`

export const FormWrapper = styled.div`
  margin-top: 0.5rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  > div {
    width: 100%;
  }

  &.list {
    justify-content: center;
    align-items: center;
  }

  @media ${medias.sm} {
    min-width: 250px;
  }
`

export const StyledButton = styled(Button)`
  padding: 4px 16px;
  height: 35px;
`
