import theme from '@/theme'

export const AVAILABLE_DATE_TYPES = {
  blocked: 'Bloqueada',
  released: 'Liberada',
}

export const AVAILABLE_DATE_COLORS = {
  blocked: theme.palette.error.main,
  released: theme.palette.success.main,
}
