import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { inviteStatus } from 'consts/types'
import { copyObject } from 'services/utils'
import { toMask, phoneMask, cnpjCpfMask, unmask } from 'services/masks'
import {
  handleSaveUser,
  closeFormEdit,
} from 'store/modules/admin/users/reducer'
import { Form, FormTextField, FormCheckbox } from 'components/CoForm'
import { Dialog } from 'components/Dialog'
import { Container, FormWrapper, StyledButton, Avatar } from './styles'
export function UserFormEdit({ loggedUser }) {
  const formRef = useRef(null)
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => state.admin.users.webUser)
  const show = useSelector((state) => state.admin.users.formEditShow)

  useEffect(() => {
    if (show) {
      if (currentUser) {
        formRef.current.setValues({
          ...currentUser,
          weu_ativo: currentUser.weu_ativo === 'active',
        })
      } else {
        formRef.current.setValues({
          weu_avatar: null,
          weu_nome: '',
          weu_cpf: '',
          weu_email: '',
          weu_telefone: '',
          weu_ativo: true,
        })
      }
    }
  }, [show, currentUser])

  const isEditing = !!currentUser

  const isSelfEditing =
    isEditing && currentUser.id === loggedUser.web_user.weu_id

  const isMasterAdminEditing =
    isEditing && !isSelfEditing && loggedUser.isMasterAdmin

  const isNotInvited =
    isEditing && currentUser.invite_status === inviteStatus.NOT_INVITED

  const disabledEmailField =
    isEditing && (!isNotInvited || isMasterAdminEditing || isSelfEditing)

  const handleSave = () => {
    const draft = copyObject(formRef.current.values)
    draft.weu_cpf = unmask(draft.weu_cpf)
    draft.id_perfil = draft.perfis_usuario?.map((p) => p.value).join(',')

    delete draft.perfis_usuario
    delete draft.perfis_disponiveis

    if (isSelfEditing) {
      delete draft.id_perfil
    }

    const userParams = {
      formValues: draft,
      webUser: currentUser,
    }

    dispatch(handleSaveUser(userParams))
  }

  const handleClose = () => dispatch(closeFormEdit())

  const getTitle = () => {
    const editingText = isSelfEditing ? 'Atualizar Perfil' : 'Atualizar Usuário'

    return isEditing ? editingText : 'Cadastrar Usuário'
  }

  return (
    <Form formRef={formRef}>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={show}
        title={getTitle()}
        actions={
          <>
            <StyledButton
              label="Cancelar"
              variant="outlined"
              color="primary"
              onClick={handleClose}
            />
            <StyledButton
              label="Salvar"
              variant="contained"
              color="primary"
              onClick={handleSave}
            />
          </>
        }
      >
        <Container>
          {isEditing && (
            <Avatar
              name="weu_avatar"
              label="Clique para alterar seu avatar"
              alt={currentUser?.weu_nome}
              disabled
            />
          )}
          <FormWrapper>
            <FormTextField
              name="weu_nome"
              label="Nome"
              type="text"
              size="small"
              variant="standard"
              disabled={isMasterAdminEditing}
            />
            <FormTextField
              name="weu_cpf"
              label="CPF/CNPJ"
              type="text"
              size="small"
              variant="standard"
              textMaskProps={toMask(cnpjCpfMask)}
              disabled={isMasterAdminEditing}
            />
            <FormTextField
              name="weu_email"
              label="E-mail"
              type="email"
              size="small"
              variant="standard"
              disabled={disabledEmailField}
            />
            <FormTextField
              name="weu_telefone"
              label="Telefone"
              type="tel"
              size="small"
              variant="standard"
              textMaskProps={toMask(phoneMask)}
              disabled={isMasterAdminEditing}
            />
            {isEditing && !isSelfEditing && (
              <div>
                <FormCheckbox name="weu_ativo" label="Ativo" />
              </div>
            )}
          </FormWrapper>
        </Container>
      </Dialog>
    </Form>
  )
}

UserFormEdit.propTypes = {
  loggedUser: PropTypes.object,
}

export default UserFormEdit
