import { createSlice } from '@reduxjs/toolkit'
import { inviteStatus } from 'consts/types'

const initialState = {
  webUsers: [],
  loading: false,
  formEditShow: false,
  webUser: null,
}

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsersRequest(state) {
      state.loading = true
    },
    getUsersSuccess(state, { payload }) {
      state.webUsers = payload
      state.loading = false
    },
    getUsersFailure(state) {
      state.loading = false
    },

    handleSaveUser(state) {
      state.loading = true
    },
    errorOnSave(state) {
      state.loading = false
    },

    showFormEdit(state, { payload }) {
      state.formEditShow = true
      state.webUser = payload
    },
    closeFormEdit(state) {
      state.formEditShow = false
      // state.webUser = null;
    },

    createUserRequest() {},
    createUserSuccess(state, { payload }) {
      state.loading = false
      state.webUsers.push(payload)
    },

    updateUserRequest() {},
    updateUserSuccess(state, { payload }) {
      state.loading = false
      state.webUsers = state.webUsers.map((webUser) =>
        webUser.id === payload.id ? payload : webUser
      )
    },

    removeUserRequest(state) {
      state.loading = true
    },
    removeUserSuccess(state, { payload }) {
      state.loading = false
      state.webUsers = state.webUsers.filter(
        (webUser) => webUser.id !== payload.id
      )
    },
    removeUserCancel(state) {
      state.loading = false
    },

    sendInviteRequest(state) {
      state.loading = true
    },
    sendInviteSuccess(state, { payload: recipient }) {
      state.loading = false
      state.webUsers = state.webUsers.map((webUser) =>
        webUser.id === recipient
          ? { ...webUser, invite_status: inviteStatus.PENDING }
          : webUser
      )
    },
    sendInviteCancel(state) {
      state.loading = false
    },
  },
})

export const {
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
  handleSaveUser,
  errorOnSave,
  showFormEdit,
  closeFormEdit,
  createUserRequest,
  createUserSuccess,
  updateUserRequest,
  updateUserSuccess,
  removeUserRequest,
  removeUserSuccess,
  removeUserCancel,
  sendInviteRequest,
  sendInviteSuccess,
  sendInviteCancel,
  logIsAsWebUserFailure,
} = users.actions
export default users.reducer
