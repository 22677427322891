import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { placa } from 'consts/regexp'
import { getLastVehiclesRequest } from 'store/modules/comuns/lastVehicles/reducer'
import { FormCombobox } from 'components/CoForm/components'

export function ComboboxLastVehicles({ defaultOptions, ...props }) {
  const dispatch = useDispatch()
  const vehicles = useSelector((state) => state.comuns.lastVehicles.vehicles)

  const options = defaultOptions.concat([
    ...vehicles.map((v) => ({ ...v, value: v.brd_placa })),
  ])

  useEffect(() => {
    if (vehicles.length === 0) dispatch(getLastVehiclesRequest())
  }, [dispatch, vehicles.length])

  return (
    <FormCombobox
      className="FormRow-plate"
      id="combo-last-vehicles"
      options={options}
      freeSolo
      autoSelect={false}
      clearOnEscape={false}
      selectOnFocus={false}
      clearOnBlur={false}
      inputProps={{
        uppercase: true,
        textMaskProps: {
          mask: placa.array,
        },
      }}
      {...props}
    />
  )
}

ComboboxLastVehicles.propTypes = {
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
}

ComboboxLastVehicles.defaultProps = {
  defaultOptions: [],
}

export default ComboboxLastVehicles
