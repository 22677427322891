import styled from 'styled-components'
import { Paper } from '@material-ui/core'

export const SettingsPaper = styled(Paper)`
  padding: 1rem;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 4%);
  background-color: ${(props) => props.theme.palette.background.paper};

  div[role='tabpanel'] > div {
    height: auto;
    overflow: hidden;
  }
`
