import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { Content } from '@/components/Content'
import { Note } from '@/components/Icons'
import { PATHS } from '@/consts/paths'

import {
  Container,
  QueryButtonRow,
  QueryButton,
  QueryIcon,
  QueryInfo,
} from './styles'

import UserRight from '@/components/UserRight'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function QueriesPage() {
  return (
    <Content title="Consultas">
      <Container>
        <QueryButtonRow>
          <QueryNavigateButton
            path={PATHS.user.queries.invoices}
            Icon={Note}
            title="Notas Fiscais"
            description="Consultar as notas fiscais emitidas e baixar documentos fiscais relacionados"
          />
        </QueryButtonRow>
      </Container>
    </Content>
  )
}

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function QueryNavigateButton({
  path,
  title,
  description,
  Icon,
  rightId,
  ...props
}) {
  const history = useHistory()

  return (
    <UserRight id={rightId}>
      <QueryButton onClick={() => history.push(path)} {...props}>
        <QueryIcon>
          <Icon />
        </QueryIcon>
        <QueryInfo>
          <span>{title}</span>
          <p>{description}</p>
        </QueryInfo>
      </QueryButton>
    </UserRight>
  )
}

QueryNavigateButton.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
}
