import React from 'react'
import { Typography } from '@material-ui/core'
import { BarLoader } from 'react-spinners'
import { NewConnectionSplashScreenContainer } from './styles'

import theme from 'theme'

export function NewConnectionSplashScreen() {
  return (
    <NewConnectionSplashScreenContainer>
      <div>
        <BarLoader
          loading
          color={theme.palette.primary.main}
          height={4}
          width={200}
        />

        <Typography component="span" color="inherit">
          Personificando usuário...
        </Typography>
      </div>
    </NewConnectionSplashScreenContainer>
  )
}
