import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addDays, isValid } from 'date-fns'

import * as medias from 'consts/medias'
import theme from 'theme'
import formatter from 'services/formatter'
import { createQueryParameters } from 'services/utils'
import { useSortData, usePagination } from 'hooks'
import { shipmentsRequest } from 'store/modules/users/queries/shipments/reducer'

import { useMediaQuery } from '@material-ui/core'
import { Controls, LimitedText } from './styles'
import {
  Content,
  Paper,
  Table,
  ChipStatus,
  DatePicker,
  TablePagination,
  TextField,
} from '../../../../components'
import ComboboxBase from 'components/Combobox/Base'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

export const shipmentStatus = [
  { id: 0, color: theme.palette.error.main, label: 'Aguardando' },
  { id: 1, color: theme.palette.grey[500], label: 'Liberada' },
  { id: 2, color: theme.palette.info.main, label: 'Carregando' },
  { id: 3, color: theme.palette.success.main, label: 'Finalizado' },
]

const FILTERS = [
  { value: 0, label: 'DATA DE ENTRADA', field: 'brd_data_entrada' },
  { value: 1, label: 'Nº PEDIDO', field: 'cod_pedido' },
  { value: 2, label: 'NOTA FISCAL', field: 'nf_numero' },
]

const FETCH_DELAY = 500 // 0.5s
const MIN_DAYS = 365

const DEFAULT_DATE = new Date()

const minDate = addDays(DEFAULT_DATE, -MIN_DAYS)
const maxDate = DEFAULT_DATE

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function ShipmentsPage() {
  const sm = useMediaQuery(medias.sm)
  const dispatch = useDispatch()

  const shipments = useSelector(
    (state) => state.users.queries.shipments.shipments
  )
  const loading = useSelector((state) => state.users.queries.shipments.loading)
  const count = useSelector((state) => state.users.queries.shipments.count)

  const [activeFilter, setActiveFilter] = useState(0)
  const [shipmentDate, setShipmentDate] = useState(DEFAULT_DATE)
  const [search, setSearch] = useState('')

  const { currentSort, onSortChange } = useSortData({
    initialField: 'brd_data_entrada',
  })

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage, reset } =
    usePagination()

  useEffect(() => {
    let timeOut = undefined

    const isFilterByDate = activeFilter === 0

    const isValidDate =
      isValid(shipmentDate) &&
      shipmentDate >= minDate &&
      shipmentDate <= maxDate

    const isValidFilter = isFilterByDate ? isValidDate : search.length > 0

    if (isValidFilter) {
      timeOut = setTimeout(() => {
        const formattedDate = formatter(String(shipmentDate)).toSimpleDate()
        const searchField = FILTERS[activeFilter].field

        const filters = {
          [searchField]: search,
          brd_data_entrada: formattedDate,
        }

        if (!isFilterByDate) {
          delete filters.brd_data_entrada
        }

        const queryParameters = createQueryParameters({
          currentSort,
          page,
          rowsPerPage,
          filters,
        })

        dispatch(shipmentsRequest(queryParameters))
      }, FETCH_DELAY)
    }

    return () => clearTimeout(timeOut)
  }, [dispatch, shipmentDate, currentSort, page, rowsPerPage, search])

  useEffect(() => {
    reset()
  }, [reset, shipmentDate])

  const handleChangeDate = (newDate) => {
    setShipmentDate(newDate)
  }

  const handleChangeSearch = (e) => {
    const newSearch = e.target.value
    setSearch(newSearch)
  }

  const handleChangeActiveFilter = (_, option) => {
    const newFilter = option.value
    setActiveFilter(newFilter)
    setSearch('')
  }

  const ShipmentObservation = ({ cellValue }) => {
    return (
      <LimitedText title={cellValue} limit={20}>
        {cellValue}
      </LimitedText>
    )
  }

  const ShipmentStatus = ({ cellValue: statusId }) => {
    const status = shipmentStatus.find((status) => status.id === statusId)
    return <ChipStatus background={status.color} label={status.label} />
  }

  const renderFilter = () => {
    switch (activeFilter) {
      case 1:
        return (
          <TextField
            fullWidth
            label="Nº Pedido"
            value={search}
            onChange={handleChangeSearch}
          />
        )
      case 2:
        return (
          <TextField
            fullWidth
            label="Nota Fiscal"
            value={search}
            onChange={handleChangeSearch}
          />
        )
      case 0:
      default:
        return (
          <DatePicker
            fullWidth
            label="Data Entrada"
            disableToolbar
            value={shipmentDate}
            onChange={handleChangeDate}
            variant={sm ? 'dialog' : 'inline'}
            minDate={minDate}
            maxDate={maxDate}
          />
        )
    }
  }

  return (
    <Content
      loading={loading}
      title="Consultar Carregamentos"
      showReturn
      sideBarStart
      Controls={
        <Controls>
          <ComboboxBase
            disableClearable
            fullWidth
            value={activeFilter}
            onChange={handleChangeActiveFilter}
            options={FILTERS}
            renderInput={(params) => (
              <TextField fullWidth label="Filtro" {...params} />
            )}
          />

          {renderFilter()}
        </Controls>
      }
    >
      <Paper className="border-none">
        <Table
          size="medium"
          data={shipments}
          columns={[
            {
              title: 'DATA',
              field: 'brd_data_entrada',
              cellFormat: 'date',
            },
            { title: 'SENHA', field: 'brd_atend_senha' },
            { title: 'PLACA', field: 'brd_placa' },
            {
              title: 'MOTORISTA',
              field: 'driver.descr',
              sortable: false,
            },
            {
              title: 'SENHA',
              field: 'brd_data_entrada',
              cellFormat: 'time',
            },
            {
              title: 'ENTRADA',
              field: 'brd_data_peso_entrada',
              cellFormat: 'time',
            },
            {
              title: 'CARGA',
              field: 'brd_dini_atendim',
              cellFormat: 'time',
            },
            {
              title: 'SAÍDA',
              field: 'brd_dsaida',
              cellFormat: 'time',
            },
            {
              title: 'TEMPO CARREGAMENTO',
              field: 'avg_load_time',
              sortable: false,
            },
            {
              title: 'OBS',
              field: 'gate_message.dsc_msg_painel',
              sortable: false,
              renderCell: ShipmentObservation,
            },

            {
              field: 'status',
              role: 'status',
              renderCell: ShipmentStatus,
              sortable: false,
            },
          ]}
          groupHeader={[{ start: 5, expand: 5, label: 'HORÁRIOS' }]}
          currentSort={currentSort}
          onSortChange={onSortChange}
        />
        <TablePagination
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      </Paper>
    </Content>
  )
}
