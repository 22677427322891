import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getDashboardRequest } from 'store/modules/users/dashboard/reducer'
import { colors } from 'theme'
import formatter from 'services/formatter'

import {
  ViewListOutlined as WalletOrdersIcon,
  WebAsset as PalletsIcon,
  WidgetsOutlined as BoxesIcon,
  AspectRatio as MetersIcon,
  FitnessCenter as WeightIcon,
  LocalShippingOutlined as TrucksInAttendanceIcon,
  Timer as PermanenceIcon,
  ClearAll as ShipmentComparativeIcon,
  GridOn as LoadedMetersComparativeIcon,
} from '@material-ui/icons'
import { Content, Card } from 'components'
import { Graph } from 'components/Charts'
import {
  Container,
  DashBoardCardList,
  ColumnCards,
  CardBody,
  InfoContent,
} from './styles'

// --------------- 𝕄𝕖𝕥𝕒𝕕𝕒𝕥𝕒 ---------------

// --------------- ℍ𝕖𝕝𝕡𝕖𝕣𝕤 ---------------

const createGraphData = (data, seriesAttr) => ({
  labels: data.map((item) => item.compet),
  series: [data.map((item) => item[seriesAttr]) || [0]],
})

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function Info({ children, decimalCases = 0 }) {
  const value = children ?? 0
  const isNumber = !!Number(value)

  return (
    <InfoContent>
      {isNumber ? formatter(value).toDecimal({ decimalCases }) : value}
    </InfoContent>
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function CarrierHomePage() {
  const dispatch = useDispatch()

  const loading = useSelector((state) => state.users.dashboard.loading)
  const orders = useSelector((state) => state.users.dashboard.orders)
  const loadings = useSelector((state) => state.users.dashboard.loadings)

  const shipmentCount = useSelector(
    (state) => state.users.dashboard.shipmentCount
  )
  const shipmentSqmtr = useSelector(
    (state) => state.users.dashboard.shipmentSqmtr
  )

  useEffect(() => {
    dispatch(getDashboardRequest())
  }, [dispatch])

  return (
    <Content loading={loading} title="Bem vindo, Transportador">
      <Container>
        <DashBoardCardList>
          <div className="cardListGrid">
            <div className="cardListGrid-columns">
              <ColumnCards>
                <Card title="Disponível - Pallets" Icon={PalletsIcon}>
                  <Info>{orders?.total_pallets}</Info>
                </Card>

                <Card title="Pedidos em Carteira" Icon={WalletOrdersIcon}>
                  <Info>{orders?.total_pedidos_res}</Info>
                </Card>
              </ColumnCards>

              <ColumnCards>
                <Card title="Disponível - Caixas" Icon={BoxesIcon}>
                  <Info>{orders?.total_caixas}</Info>
                </Card>
                <Card title="Disponível - Metros Quadrados" Icon={MetersIcon}>
                  <Info>{orders?.total_m2}</Info>
                </Card>
              </ColumnCards>
            </div>
            <Card title="Disponível - Peso (kg)" Icon={WeightIcon}>
              <Info>{orders?.total_kg}</Info>
            </Card>
          </div>
          <ColumnCards className="reverse">
            <Card
              title="Caminhões em Atendimento"
              Icon={TrucksInAttendanceIcon}
            >
              <Info>{loadings?.total_atual}</Info>
            </Card>
            <Card title="Média de Permanência mês atual" Icon={PermanenceIcon}>
              <Info>{loadings?.med_horas}</Info>
            </Card>
          </ColumnCards>
        </DashBoardCardList>
        <DashBoardCardList className="card-list-graph">
          <Card
            title="Comparativo de Carregamentos - número de veículos atendidos"
            Icon={ShipmentComparativeIcon}
          >
            <CardBody>
              <Graph
                decimalCases={0}
                color={colors.color300}
                data={createGraphData(shipmentCount, 'num_carreg')}
              />
            </CardBody>
          </Card>
          <Card
            title="Comparativo de Metros Quadrados Carregados"
            Icon={LoadedMetersComparativeIcon}
          >
            <CardBody>
              <Graph
                type="Line"
                color={colors.color300}
                data={createGraphData(shipmentSqmtr, 'num_m2')}
              />
            </CardBody>
          </Card>
        </DashBoardCardList>
      </Container>
    </Content>
  )
}

export default CarrierHomePage
