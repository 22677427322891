import styled, { css } from 'styled-components'
import theme, { colors } from '../../theme'
import * as medias from '../../consts/medias'
import { MuiAvatar, Notifications } from '../Icons'

export const Container = styled.div`
  position: relative;
  background-color: ${colors.color100};

  display: flex;
  height: 64px;

  z-index: 1;

  @media ${medias.sm} {
    height: auto;
    width: 100%;

    z-index: 11;
    position: fixed;
    top: 0;
  }
`

const menuCard = css`
  z-index: 2;
`

export const CompanyLogo = styled.div`
  ${menuCard}
  background-color: ${colors.color500};
  padding: 11px 13px;
  height: 96px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  > span {
    font-size: 17px;
    line-height: 24px;

    color: ${colors.white};
  }

  width: 310px;

  > img {
    width: 100%;
    max-width: 200px;
    height: auto;
  }

  @media ${medias.downLg} {
    display: none;
  }
`

export const ToolBar = styled.div`
  ${menuCard}
  height: 100%;
  background-color: ${colors.color500};

  margin-left: 16px;
  margin-top: 32px;

  flex: 1;
  display: flex;
  align-items: center;

  ${CompanyLogo} {
    display: none;
  }

  @media ${medias.downLg} {
    margin-left: 0;
  }

  @media ${medias.sm} {
    padding: 5px 0;
    margin-top: 0;
  }

  @media ${medias.md} {
    ${CompanyLogo} {
      flex: 10;
      display: flex;
      height: auto;
      padding: 0;

      img {
        max-width: 150px;
        width: 100%;
      }

      span {
        display: none;
        font-size: 14px;
      }
    }
  }
`

export const Menu = styled.div`
  flex: 1;
  margin: 0 13px;

  .AppBar-MenuItem {
    > a .MenuItem-label {
      top: 7px;
      position: absolute;
    }
  }

  ${theme.breakpoints.down('sm')} {
    margin: 0;
  }
`

export const Constrols = styled.div`
  display: none; // flex
  align-items: center;

  margin-right: 44px;
  svg {
    cursor: pointer;
  }
  .MuiBadge-badge {
    height: 15px;
    min-width: 15px;
    padding: 0 0 3px;

    top: 8px;
    right: 2px;
  }
  .MuiBadge-colorError {
    background-color: ${colors.notification};
  }
`

export const Profile = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  background-color: ${colors.color700};
  height: 100%;
  max-width: 350px;

  padding-left: 4px;
  border-radius: 50px 0 0 50px;

  @media ${medias.md} {
    flex: 0;
    margin-right: 13px;
    background-color: unset;
  }
`

export const Avatar = styled(MuiAvatar)`
  cursor: pointer;

  width: 55px;
  height: 55px;
  border-radius: 50%;

  &:hover {
    opacity: 0.8;
  }

  @media ${medias.md} {
    width: 40px;
    height: 40px;
  }
`

export const ProfileInfo = styled.div`
  margin: 0 15px;

  display: flex;
  flex-direction: column;
  color: ${colors.white};

  > span:nth-child(1) {
    font-size: 12px;
    margin-bottom: 3px;
    max-width: 200px;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  hr {
    border-color: ${colors.color200};
  }

  > span {
    font-size: 11px;
  }

  @media ${medias.sm} {
    display: none;
  }
`

export const SignOut = styled.div`
  cursor: pointer;

  margin: 0 auto;
  margin-right: 25.5px;

  display: flex;
  align-items: center;
  flex-direction: column;

  svg {
    width: 25px;
    flex-shrink: 0;
    fill: ${colors.white};
    &:hover {
      path {
        fill: ${colors.main};
      }
    }
  }

  span {
    color: ${colors.white};
  }

  &.MenuItem {
    margin: 0;
    margin-top: 15px;
    span {
      visibility: hidden;
    }
    svg {
      width: 32px;
    }
  }

  @media ${medias.md} {
    &:not(.MenuItem) {
      display: none;
    }
  }
`

export const CompanyName = styled.div`
  display: block;

  position: absolute;
  right: 25.5px;
  top: 10px;

  color: ${colors.color500};
  font-size: 12px;

  @media ${medias.md} {
    visibility: hidden;
  }
`

export const NotificationIcon = styled(Notifications)`
  color: ${colors.white};
  font-size: 1.7rem;
`
