import { all, takeLatest, call, put } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { PATHS } from 'consts/paths'
import { inviteStatus } from 'consts/types'
import apiEndPoints from 'consts/apiEndPoints'
import api from 'services/api'
import history from 'services/history'
import handleErrors from 'services/handleErrors'
import { saveProfileTheme } from 'services/colors'

import { confirmationDialog } from 'components/ConfirmationDialog'
import { configAuthorization, getRights } from '../../auth/sagas'
import { signInSuccess, signOutRequest } from '../../auth/reducer'
import { setUser } from '../../user/reducer'
import {
  getCompaniesRequest,
  getCompaniesSuccess,
  getCompaniesFailure,
  createCompanyRequest,
  updateCompanyRequest,
  removeCompanyRequest,
  removeCompanySuccess,
  handleSaveCompany,
  errorOnSave,
  closeFormEdit,
  sendInviteRequest,
  sendInviteSuccess,
  sendInviteCancel,
  logIsAsCompanyRequest,
  logIsAsCompanyFinish,
} from './reducer'

function* getCompanies() {
  try {
    const response = yield call(api.get, apiEndPoints.webCompanies.root)
    const webCompanies = response.data

    yield put(getCompaniesSuccess(webCompanies))
  } catch (error) {
    handleErrors(error, 'Não foi possível buscar as empresas')
    yield put(getCompaniesFailure())
  }
}

function* createCompany({ payload }) {
  try {
    const requestBody = {
      cod_empresa: payload.cod_empresa,
      wee_tipo: payload.wee_tipo,
      wee_email: payload.wee_email,
      dsc_empresa: payload.dsc_empresa,
      emp_razao_social: payload.emp_razao_social,
      emp_cidade: payload.emp_cidade,
      dsc_uf: payload.dsc_uf,
    }

    yield call(api.post, apiEndPoints.webCompanies.root, requestBody)
    toast.success('Empresa cadastrada com sucesso')

    // -------------------------------

    yield put(closeFormEdit())
    yield put(getCompaniesRequest())
  } catch (error) {
    handleErrors(error, 'Não foi possível criar a empresa')
    yield put(errorOnSave())
  }
}

function* updateCompany({ payload }) {
  try {
    const { formValues, webCompany } = payload
    const emailChanged = formValues.wee_email !== webCompany.wee_email

    const alreadyInvited =
      webCompany.admin_web_user?.invite_status !== inviteStatus.NOT_INVITED

    if (alreadyInvited && emailChanged) {
      const confirm = yield call(
        confirmationDialog.open,
        'Atualizar e-mail ?',
        'Caso o e-mail seja alterado, um novo convite será enviado para a empresa. O acesso ficará suspenso até a validação do novo convite. Deseja continuar ?'
      )

      confirmationDialog.close()

      if (!confirm) {
        yield put(errorOnSave())
        return
      }
    }

    yield call(
      api.put,
      apiEndPoints.webCompanies.onMember(webCompany.id),
      formValues
    )
    toast.success('Empresa atualizada com sucesso')

    // -------------------------------

    yield put(closeFormEdit())
    yield put(getCompaniesRequest())
  } catch (error) {
    handleErrors(error, 'Não foi possível atualizar o usuário')
    yield put(errorOnSave())
  }
}

function* removeCompany({ payload: webCompany }) {
  try {
    const webUser = webCompany.admin_web_user
    const confirm = yield call(
      confirmationDialog.open,
      'Remover Empresa',
      `Tem certeza que deseja remover a empresa ${webUser.weu_nome}`
    )

    confirmationDialog.close()

    if (!confirm) {
      yield put(errorOnSave())
      return
    }

    // -------------------------------

    yield call(api.delete, apiEndPoints.webCompanies.onMember(webCompany.id))
    yield put(removeCompanySuccess(webCompany))

    toast.success('Empresa removida com sucesso')
  } catch (error) {
    handleErrors(error, 'Não foi possível remover a empresa')
    yield put(errorOnSave())
  }
}

function* logIsAsCompany({ payload: webCompany }) {
  try {
    const response = yield call(api.post, apiEndPoints.webUsers.changeUser, {
      weu_id: webCompany.admin_web_user?.id,
    })

    const token = response.data.auth_token
    const profile = response.data.profile

    // Access profile
    const profiles = profile.web_user.profiles
    const rights = yield call(getRights, profiles)

    configAuthorization(token)
    saveProfileTheme(profile.web_company.wee_tipo)

    yield put(signInSuccess({ token, rights }))
    yield put(setUser(profile))

    /** Go to company type default path, ex: transportadora/pedidos */
    history.push(
      PATHS.defaultPath(profile.web_company.wee_tipo, profile.web_user.weu_tipo)
    )

    yield put(logIsAsCompanyFinish())
  } catch (error) {
    handleErrors(error, 'Não foi possível completar o acesso [empresa]')
    yield put(logIsAsCompanyFinish())
    yield put(signOutRequest())
  }
}

function* sendInvite({ payload: webCompany }) {
  const webUser = webCompany.admin_web_user
  try {
    const confirm = yield call(
      confirmationDialog.open,
      'Enviar Convite',
      `Enviar convite para a empresa ${webUser.weu_email}`
    )
    confirmationDialog.close()

    if (!confirm) {
      yield put(sendInviteCancel())
      return
    }

    const response = yield call(api.post, apiEndPoints.invites.root, {
      weu_id: webUser.id,
    })
    const recipient = response.data.weu_id

    yield put(sendInviteSuccess(recipient))
    toast.success('Convite enviado com sucesso')
  } catch (error) {
    yield put(sendInviteCancel())
    handleErrors(error, 'Não foi possível enviar o convite')
  }
}

// --------------------------------

function* handleSave({ payload }) {
  const isUpdate = !!payload.webCompany.id
  if (isUpdate) yield put(updateCompanyRequest(payload))
  else yield put(createCompanyRequest(payload.formValues))
}

export default all([
  takeLatest(getCompaniesRequest.type, getCompanies),
  takeLatest(handleSaveCompany.type, handleSave),
  takeLatest(createCompanyRequest.type, createCompany),
  takeLatest(updateCompanyRequest.type, updateCompany),
  takeLatest(removeCompanyRequest.type, removeCompany),
  takeLatest(sendInviteRequest.type, sendInvite),
  takeLatest(logIsAsCompanyRequest.type, logIsAsCompany),
])
