import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PATHS } from 'consts/paths'
import { unreadedBulletinsRequest } from 'store/modules/comuns/bulletins/reducer'
import { MenuItem } from '../MenuItem'
import { Inbox } from '../../Icons'

import RIGHTS from '__access_profiles__'
import UserRight from 'components/UserRight'

export const CommonMenuItems = () => {
  const interval = 300000 // 3 mnts
  const dispatch = useDispatch()

  const unreadedBulletins = useSelector(
    (state) => state.comuns.bulletins.unreadedBulletins
  )

  const fetchUnreadedBulletins = useCallback(
    () => dispatch(unreadedBulletinsRequest()),
    [dispatch]
  )

  useEffect(() => {
    fetchUnreadedBulletins()
  }, [fetchUnreadedBulletins])

  useEffect(() => {
    const unreadedBulletinsFetchInterval = setInterval(
      () => fetchUnreadedBulletins(),
      interval
    )

    return () => clearInterval(unreadedBulletinsFetchInterval)
  }, [fetchUnreadedBulletins])

  return (
    <>
      <UserRight id={RIGHTS.consultaAvisos.id}>
        <MenuItem
          Icon={Inbox}
          label="Avisos"
          path={PATHS.common.bulletins}
          badgeContent={unreadedBulletins}
          badgePosition={{
            top: 6,
            right: 5,
            topactive: 6,
            rightactive: 6,
          }}
        />
      </UserRight>
    </>
  )
}

export default CommonMenuItems
