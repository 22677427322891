import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { isNil } from 'ramda'
import { useDispatch } from 'react-redux'
import apiEndPoints from 'consts/apiEndPoints'
import { readBulletinRequest } from 'store/modules/comuns/bulletins/reducer'
import api from 'services/api'
import handleErrors from 'services/handleErrors'
import { downloadFile, isEmptyOrNil } from 'services/utils'
import { Stepper, Step, StepLabel } from '@material-ui/core'
import Dialog from 'components/Dialog'
import Chip from 'components/Chip'
import {
  BulletinContainer,
  Actions,
  CloseButton,
  MessageContainer,
  AttachmentsContainer,
} from './styles'

// --------------- ℂ𝕠𝕞𝕡𝕠𝕟𝕖𝕟𝕥𝕤 ---------------

function AttachmentChip({ attachment, ...props }) {
  return (
    <Chip
      title="Fazer download do anexo"
      className="read__only"
      label={[attachment.vca_nome, attachment.vca_extensao].join('.')}
      {...props}
    />
  )
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function Bulletin({ bulletins, onClose, ...props }) {
  const dispatch = useDispatch()
  const [attachments, setAttachments] = useState(null)
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set())

  const steps = bulletins.map(() => null)
  const isMultipleBulletins = steps.length > 1

  const bulletin = useMemo(
    () => bulletins[activeStep] ?? {},
    [bulletins, activeStep]
  )

  useEffect(() => {
    if (bulletin) {
      const alreadyRead = bulletin.bulletin_recipients?.[0]?.vcd_data_leitura
      if (!alreadyRead)
        dispatch(readBulletinRequest(bulletin))

        // fetch bulletin attachments
      ;(async function () {
        try {
          const response = await api.get(
            apiEndPoints.miscellaneous.bulletinAttachments(bulletin.id)
          )
          setAttachments(response.data.bulletin_attachments)
        } catch (error) {
          handleErrors(error, 'Não foi possível buscar os anexos')
        }
      })()
    }
  }, [dispatch, bulletin])

  const handleDownloadFile = (attachment) => () =>
    downloadFile(attachment, attachment.vca_extensao, 'attachment', 'vca_nome')

  const isStepSkipped = (step) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    setAttachments(null)
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setAttachments(null)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const buttonProps = () => {
    let buttonProps = {}
    const isLastStep = activeStep === steps.length - 1

    buttonProps.label = isLastStep ? 'Fechar' : 'Próximo'
    buttonProps.className = isLastStep ? 'error' : 'info'
    buttonProps.onClick = isLastStep ? onClose : handleNext

    return buttonProps
  }

  return (
    <Dialog
      {...props}
      open={Boolean(bulletin)}
      title={bulletin.vc_assunto}
      fullWidth
      maxWidth="md"
      actions={
        <Actions
          justifyContent={isMultipleBulletins ? 'space-between' : 'flex-end'}
        >
          {isMultipleBulletins && (
            <CloseButton
              label="Anterior"
              onClick={handleBack}
              disabled={activeStep === 0}
              variant="outlined"
              color="primary"
            />
          )}
          <CloseButton {...buttonProps()} color="primary" variant="outlined" />
        </Actions>
      }
    >
      <BulletinContainer>
        {isMultipleBulletins && (
          <Stepper orientation="horizontal" activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {}
              const labelProps = {}
              if (isStepSkipped(index)) {
                stepProps.completed = false
              }
              return (
                <Step key={index} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        )}

        {/* Message */}
        <MessageContainer
          variant={isMultipleBulletins ? 'multiple' : 'default'}
          className={clsx('html__content', { isMultipleBulletins })}
          dangerouslySetInnerHTML={{ __html: bulletin.vc_mensagem }}
        />

        {/* Attachments */}
        <AttachmentsContainer>
          <span>Anexos</span>

          <div>
            {isEmptyOrNil(attachments) ? (
              <span className="loading__text">
                {isNil(attachments)
                  ? 'Buscando anexos...'
                  : 'Não possui anexos'}
              </span>
            ) : (
              attachments.map((attachment, index) => (
                <AttachmentChip
                  attachment={attachment}
                  key={index}
                  onClick={handleDownloadFile(attachment)}
                />
              ))
            )}
          </div>
        </AttachmentsContainer>
      </BulletinContainer>
    </Dialog>
  )
}

Bulletin.propTypes = {
  bulletin: PropTypes.array,
  onClose: PropTypes.func,
}

export default Bulletin
