import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import parse from 'autosuggest-highlight/parse'
import diacritic from 'diacritic'
import { merge } from 'ramda'

import theme from '../../../theme'

import Loader from '@material-ui/core/CircularProgress'
import { FormTextField } from '../../CoForm'
import {
  StyledAutoComplete,
  InputContainer,
  OptionContainer,
  OptionPicture,
  Parts,
} from './styles'
import { InputAdornment } from '@material-ui/core'

// --------------- 𝕌𝕥𝕚𝕝𝕤 ---------------

function customMatch(text, query) {
  if (!('string' === typeof query && 'string' === typeof text)) return []

  let t = text.toLowerCase()
  let q = query.trim().toLowerCase()
  const ql = q.length

  if (!(0 < ql && 0 < t.length)) {
    return []
  }

  t = diacritic.clean(t)
  q = diacritic.clean(q)

  let r = []

  let i = t.indexOf(q)
  while (i !== -1) {
    r.push([i, i + ql])
    i = t.indexOf(q, i + ql)
  }
  return r
}

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export function ComboboxInfo({
  name,
  value,
  label,
  options,
  inputProps,
  getOptionPicture,
  onChange,
  onAfterChange,
  onInputChange,
  error,
  helperText,
  variant,
  ...props
}) {
  const currentOption = options.find((option) => option.value === value) || null

  function handleChange(_, option) {
    const params = [
      {
        target: { name, value: option?.value },
      },
      option,
    ]

    onChange(...params)
    if (onAfterChange) onAfterChange(...params)
  }

  function handleInputChange(e) {
    // Used in combobox with API search capabilities
    if (typeof onInputChange === 'function') onInputChange(e)

    if (!props.freeSolo) return
    onChange({
      target: { name, value: e.target.value },
    })
  }

  function getPicture(option) {
    if (option && getOptionPicture) return getOptionPicture(option)
    else return null
  }

  function getOptionSelected(option, value) {
    return option.value === value.value
  }

  function getOptionLabel(option) {
    const withOption = option.label ?? String(option.value)
    return option ? withOption : ''
  }

  return (
    <StyledAutoComplete
      autoSelect
      clearOnEscape
      selectOnFocus
      clearOnBlur
      fullWidth
      options={options}
      getOptionDisabled={(option) => option.disabled}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={handleChange}
      value={currentOption}
      noOptionsText="Nenhum valor foi encontrado"
      loadingText="Buscando opções..."
      clearText="Limpar"
      renderInput={(params) => {
        const picture = null

        if (props.loading) {
          params.InputProps.endAdornment = (
            <InputAdornment position="start">
              <Loader
                size={inputProps?.size === 'small' ? 10 : 15}
                color="primary"
              />
            </InputAdornment>
          )
        }

        return (
          <InputContainer className={clsx({ hasPicture: Boolean(picture) })}>
            {picture && <OptionPicture src={picture} />}
            <FormTextField
              name={name}
              onChange={handleInputChange}
              fullWidth
              label={label}
              error={error}
              helperText={helperText}
              variant={variant}
              {...params}
              {...inputProps}
              InputProps={merge(params.InputProps, inputProps?.InputProps)}
            />
          </InputContainer>
        )
      }}
      renderOption={(option, { inputValue }) => {
        const effectiveLabel = option.label || option.value

        const matches = customMatch(effectiveLabel, inputValue)
        const parts = parse(effectiveLabel, matches)

        const picture = getPicture(option)

        return (
          <OptionContainer className={clsx({ hasPicture: Boolean(picture) })}>
            {picture && <OptionPicture src={picture} />}
            <div>
              <Parts>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontSize: '14px',
                      color: part.highlight
                        ? theme.palette.primary.main
                        : 'rgba(0,0,0,0.87)',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </Parts>
              {option.secondary && (
                <span className="secondary-text">{option.secondary}</span>
              )}
            </div>
          </OptionContainer>
        )
      }}
      {...props}
    />
  )
}

ComboboxInfo.propTypes = {
  getOptionPicture: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string,
    })
  ),
}

ComboboxInfo.defaultProps = {
  options: [],
}

export default ComboboxInfo
