import React, { useMemo, useState } from 'react'
import api from 'services/api'
import apiEndPoints from 'consts/apiEndPoints'
import { CircularProgress } from '@material-ui/core'
import {
  Add,
  Email,
  LockOpenOutlined as LkOpened,
  LockOutlined as LkClosed,
  VpnKey,
} from '@material-ui/icons'
import { ListItem } from 'components/ListItem'
import { useCurrentForm } from 'components/CoForm'
import { toast } from 'react-toastify'

export const AccessButton = () => {
  const [loading, setLoading] = useState(false)
  const form = useCurrentForm()
  const webCompany = form.values

  const listItemProps = useMemo(() => {
    const withToken = webCompany.wee_api_chave
    const withTempToken = webCompany.temp_wee_api_chave || withToken
    const isActive = webCompany.wee_token_ativo

    const props = {}
    props.text = {}
    props.avatar = loading ? (
      <CircularProgress size={20} />
    ) : (
      <VpnKey color="primary" />
    )

    props.text.primary = 'Consulta de estoque'
    props.actions = []

    /**
     * Save the company code in the token field,
     * after saving this value will be encrypted in an access token
     */
    const handleAdd = () => {
      form.setFieldValue('temp_wee_api_chave', webCompany.cod_empresa)
    }

    /**
     * Change value of `wee_token_ativo`
     *
     * @default `true`
     */
    const handleToogleTokenActive = () => {
      form.setFieldValue('wee_token_ativo', !webCompany.wee_token_ativo)
    }

    /**
     * Send the token to `webCompany` e-mail
     */
    const handleSendTokenToCompanyEmail = async () => {
      setLoading(true)
      try {
        await api.post(apiEndPoints.webCompanies.sendWebToken(webCompany.id))
        toast.success('E-mail enviado com sucesso')
      } catch (error) {
        toast.error(
          'Não foi possivel enviar o e-mail. Tente novamento mais tarde'
        )
      }
      setLoading(false)
    }

    const addToken = {
      tooltip: 'Adicionar',
      icon: <Add color="primary" />,
      onClick: handleAdd,
    }

    props.text.secondary = 'Adicionar chave de acesso para consulta de estoque'
    props.actions[0] = addToken

    if (withTempToken) {
      const toogleTokenActive = {
        tooltip: isActive ? 'Desativar' : 'Ativar',
        icon: isActive ? (
          <LkOpened color="primary" />
        ) : (
          <LkClosed color="primary" />
        ),
        onClick: handleToogleTokenActive,
      }

      const sendTokenToCompanyEmail = {
        tooltip: 'E-mail',
        icon: <Email color="primary" />,
        onClick: handleSendTokenToCompanyEmail,
      }

      props.text.secondary = isActive
        ? withToken
          ? 'Clique para reenviar a chave de accesso'
          : 'Clique em salvar para criar a chave de acesso'
        : 'Ativar chave de acesso'

      props.actions[0] = toogleTokenActive

      if (isActive && withToken) {
        props.actions[0] = sendTokenToCompanyEmail
        props.actions[1] = toogleTokenActive
      }
    }

    return props
  }, [webCompany, loading])

  return <ListItem button {...listItemProps} />
}

export default AccessButton
